import DataGrid from '@/components/DataGrid';
import { Log } from '@/helpers/debug';
import useDataGridState from '@/hooks/useDataGridState';
import { Container, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GridRowParams, GridSlotsComponent } from '@mui/x-data-grid-premium';
import {
    AutoRefreshButton,
    DataGridMobileRenderCell,
    DataGridRenderCell,
    useDataGridColumns,
    useDataGridRows,
    useOnMount,
} from '@progress/base-ui';
import { useSnackbar } from 'notistack';
import { CSSProperties, useCallback, useState } from 'react';
import {
    CustomGridToolbar,
    limitedColumnProps,
    minWidthColumnProps,
    MobileGrid,
    MobileItemRow,
} from '../components/DataGridComponents';
import { useTypedTranslation } from '../definitions';
import { Deployment } from '../definitions/autogenerated/types';
import { getImage } from '../helpers';
import { useReduxDispatch, useTypedSelector } from '../store/configureStore';
import { fetchProgressApps } from '../store/data/progressApps/actions';
import { progressAppsSelectors } from '../store/data/progressApps/progressAppsSlice';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '2100px !important',
}));

const rootStyle: CSSProperties = {
    margin: '0',
    minHeight: '100%',
};
const divStyle: CSSProperties = {
    display: 'block',
    width: '100%',
};

const divStyleHead: CSSProperties = {
    display: 'inline-block',
    width: '100%',
    alignItems: 'relative',
    margin: '1',
};

const customComponents: Partial<GridSlotsComponent> = {
    toolbar: CustomGridToolbar,
};

const renderApplicationType: DataGridRenderCell<Deployment, 'ApplicationType'> = ({ row }) => getImage(row.ApplicationType);

const ProgressAppsList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const apps = useTypedSelector(progressAppsSelectors.selectAll);

    const { gridState, setGridState } = useDataGridState({
        dataGridId: 'progressAppsList',
        defaults: {
            sorting: {
                ApplicationType: 'asc',
            },
        },
    });

    const rows = useDataGridRows<Deployment>(() => apps, [apps]);

    const { t } = useTypedTranslation();
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const loadApps = useCallback(() => {
        setIsLoading(true);

        dispatch(fetchProgressApps())
            .unwrap()
            .catchError((e) => {
                enqueueSnackbar(t('main', 'loadingError'), { variant: 'error' });
                Log.error(e.Description);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, enqueueSnackbar, t]);

    // fetch the data once the component is mounted
    useOnMount(() => {
        loadApps();
    });

    const columns = useDataGridColumns<Deployment>(
        (builder) => {
            builder
                .addColumn('ApplicationType', {
                    ...limitedColumnProps,
                    minWidth: 200,
                    width: 400,
                    align: 'center',
                    alignCaption: 'center',
                    render: renderApplicationType,
                })
                .addColumn('AppName', {
                    ...limitedColumnProps,
                    ...minWidthColumnProps,
                    caption: t('main', 'name'),
                })
                .addColumn('ReleaseTag', {
                    ...limitedColumnProps,
                    ...minWidthColumnProps,
                    caption: t('main', 'releaseTag'),
                });
        },
        [t]
    );

    const renderMobileView: DataGridMobileRenderCell<Deployment> = useCallback(
        ({ row }) => (
            <MobileGrid
                gridKey="appsMobile"
                spacing={0}
                sx={{ marginBottom: '10px' }}
            >
                <MobileItemRow
                    title=""
                    isObject
                >
                    <div style={{ margin: 'auto', marginBottom: 10 }}>{getImage(row.ApplicationType)}</div>
                </MobileItemRow>
                <MobileItemRow title={`${t('main', 'name')}:`}>{row.AppName}</MobileItemRow>
                <MobileItemRow title={`${t('main', 'releaseTag')}:`}>{row.ReleaseTag}</MobileItemRow>
            </MobileGrid>
        ),
        [t]
    );

    return (
        <StyledContainer
            maxWidth="lg"
            className="container"
        >
            <div
                className="container"
                style={rootStyle}
            >
                <div
                    className="container"
                    style={divStyle}
                >
                    <div style={divStyleHead}>
                        <Box
                            m={1}
                            //margin
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <h1 className="containerTitle">{t('apps', 'title')}</h1>
                            <AutoRefreshButton
                                variant="outlined"
                                loading={isLoading}
                                onRefresh={loadApps}
                                onClick={loadApps}
                                refreshInterval={120000}
                            >
                                {t('main', 'refresh')}
                            </AutoRefreshButton>
                        </Box>
                    </div>

                    <Paper className="container">
                        <DataGrid
                            dataGridId="progressAppsList"
                            rows={rows}
                            mobileView={{
                                maxWidth: 900,
                                render: renderMobileView,
                            }}
                            columns={columns}
                            getRowId={getRowId}
                            onRowClick={onAppRowClick}
                            gridState={gridState}
                            onGridStateChange={setGridState}
                            disableAggregation
                            loading={isLoading}
                            slots={customComponents}
                            rowSelection="none"
                        />
                    </Paper>
                </div>
            </div>
        </StyledContainer>
    );
};

export default ProgressAppsList;

const getRowId = (app: Deployment) => app.Url;
const onAppRowClick = (params: GridRowParams<Deployment>) => window.open(params.row.Url, '_blank')?.focus();
