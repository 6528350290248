import { PropsWithChildren, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ProgressApp } from './ProgressApp';

type ProgressRouterProps = PropsWithChildren<object>;

/**
 * BrowserRouter that automatically sets the basename to the application root
 * @param props children of the router
 */
export const ProgressRouter = (props: ProgressRouterProps) => {
    const routeBase = useMemo(() => {
        return ProgressApp.getApplicationRoot();
    }, []);

    return <BrowserRouter basename={routeBase}>{props.children}</BrowserRouter>;
};
