import { AppBar, Toolbar } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ColorScheme } from './colors';

type ProgressAppBarProps = PropsWithChildren<{
    colors: ColorScheme;
}>;

export const ProgressAppBar = (props: ProgressAppBarProps) => {
    return (
        <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        >
            <Toolbar sx={{ padding: '0px !important', backgroundColor: props.colors.background }}>{props.children}</Toolbar>
        </AppBar>
    );
};
