import { Backdrop, Popper, PopperProps, styled, SxProps, Theme } from '@mui/material';
import { DateTimePicker, DateTimePickerProps, DateTimePickerSlots } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type CustomDateTimePickerProps = {
    value: DateTime;
    onChange: (date: DateTime | null) => void;
    label?: string;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    invalid?: boolean;
    minDate?: DateTime;
    maxDate?: DateTime;
};

/**
 * Minimal wrapper to handle onAccept correctly and to debounce typing
 *
 * @param props need to be provided as {@link CustomDateTimePickerProps} containing value and onChange
 */
export const CustomDateTimePicker = memo(function CustomDateTimePicker(props: CustomDateTimePickerProps) {
    const [value, setValue] = useState(props.value);
    const [open, setOpen] = useState<boolean>(false);
    const timeOutHandle = useRef<NodeJS.Timeout>();

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleOnAccept = useCallback(
        (v: DateTime | null) => {
            if (v) {
                props.onChange(v);
            }

            // after accepting the value we need to make sure the value is up to date
            setValue(props.value);
        },
        [props]
    );

    const handleOnchange = useCallback(
        (v: DateTime | null) => {
            if (v) {
                setValue(v);

                // debounce typing
                if (timeOutHandle.current) {
                    clearTimeout(timeOutHandle.current);
                }

                timeOutHandle.current = setTimeout(() => {
                    // if the picker is not open, we assume the change was trigger by typing
                    if (!open && v.isValid) {
                        handleOnAccept(v);
                    }
                }, 250);
            }
        },
        [handleOnAccept, open]
    );

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const slotProps: DateTimePickerProps<DateTime, false>['slotProps'] = useMemo(
        () => ({
            textField: {
                margin: 'dense',
                size: 'small',
                error: props.invalid,
            },
            actionBar: { actions: [] }, // dont show the ok button
        }),
        [props.invalid]
    );

    return (
        <DateTimePicker
            sx={props.sx}
            open={open}
            disabled={props.disabled}
            reduceAnimations
            label={props.label}
            value={value}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onChange={handleOnchange}
            onAccept={handleOnAccept}
            onOpen={handleOpen}
            onClose={handleClose}
            slots={slots}
            slotProps={slotProps}
        />
    );
});

const StyledPopper = styled(Popper)(({ theme }) => ({ zIndex: theme.zIndex.modal + 1 }));

const PopperWithBackdrop = (props: PopperProps) => {
    return (
        <Backdrop
            open={props.open}
            sx={(theme) => ({ zIndex: theme.zIndex.modal + 1, background: 'transparent' })}
        >
            <StyledPopper {...props} />
        </Backdrop>
    );
};

// since the DateTimePicker does not have a backdrop by default, we have to add a backdrop to prevent
// that an outside click (for example Load/Refresh button) triggers before the onChange is fired.
// this would cause that the request is sent with a not yet updated value
const slots: DateTimePickerSlots<DateTime> = {
    popper: PopperWithBackdrop,
};
