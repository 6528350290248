import { DataGridId } from '@/definitions/dataGrid';
import { styled } from '@mui/material';
import { BaseDataGrid, BaseDataGridProps, DataGridRowType } from '@progress/base-ui';
import { defaultGridProps } from './DataGridComponents';
import DataGridStyles from './DataGridStyles.css';

const StyledDataGrid = styled(BaseDataGrid)(DataGridStyles);

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type StyledDataGridProps<T extends DataGridRowType> = BaseDataGridProps<T> & {
    // eventually additional props
    dataGridId: DataGridId;
};

export default function TypedStyledDataGrid<T extends DataGridRowType>(props: StyledDataGridProps<T>) {
    const { dataGridId, className, ...dataGridProps } = props;
    const gridClassName = `dataGrid-${dataGridId}`;
    return (
        <StyledDataGrid
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- type assertion needed for styled since it doesn't pass the generics properly */
            {...(dataGridProps as any)}
            className={className ? `${className} ${gridClassName}` : gridClassName}
            {...defaultGridProps<T>()}
        />
    );
}
