import { WarningAmber } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, InputLabel, TextField } from '@mui/material';
import axios from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { api } from '../definitions';

export type AddCustomerDomainProps = {
    fieldKey: string;
    fieldPlaceholder: string | undefined;
    fieldLabel: string;
    index: number;
    values: Record<string, string | number | boolean | string[]>;
    onChange: (key: string, newValue: string | number | boolean | string[]) => void;
};

const AddCustomerDomain = ({ fieldKey, fieldPlaceholder, fieldLabel, index, values, onChange }: AddCustomerDomainProps) => {
    const [similarName, setSimilarName] = useState<string[] | undefined>();
    const [loadingNameCheck, setLoadingNameCheck] = useState<boolean>(false);

    const namesCheck = useCallback((name: string, controller: AbortController) => {
        name = name.toLowerCase();
        if (name === '') {
            setSimilarName(undefined);
            setLoadingNameCheck(false);
        } else {
            axios
                .get<string[]>(api.progressAIRequest(name), { signal: controller.signal })
                .then((response) => {
                    if (response.data.length) {
                        setSimilarName(response.data);
                    } else {
                        setSimilarName(undefined);
                    }
                })
                .finally(() => setLoadingNameCheck(false));
        }
    }, []);

    const [tempVal, setTempVal] = useState<string>('');

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setLoadingNameCheck(true);
            const { value } = e.target;
            onChange(fieldKey, value);
            setTempVal(value);
        },
        [fieldKey, onChange]
    );

    useEffect(() => {
        const abortController = new AbortController();
        const debounceTimer = setTimeout(() => {
            namesCheck(tempVal, abortController);
        }, 500);
        return () => {
            abortController.abort();
            clearTimeout(debounceTimer);
        };
    }, [namesCheck, tempVal]);

    return (
        <>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <InputLabel
                    htmlFor={`${fieldKey}-${index}`}
                    style={{ width: 225, textAlign: 'right' }}
                >
                    {fieldLabel}
                </InputLabel>
                <TextField
                    id={`${fieldKey}-${index}`}
                    fullWidth
                    autoFocus={index === 0}
                    autoComplete="off"
                    placeholder={fieldPlaceholder}
                    value={values[fieldKey] ?? ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                />
            </div>

            <div>
                <Alert
                    severity="warning"
                    sx={{ bgcolor: 'rgb(255, 244, 229)' }}
                    style={{ alignItems: 'center', padding: '10px' }}
                    variant="outlined"
                    icon={
                        <div style={{ width: '50px', textAlign: 'center', verticalAlign: 'center', marginRight: '18px' }}>
                            {!loadingNameCheck ? (
                                <WarningAmber />
                            ) : (
                                <LoadingButton
                                    loading
                                    style={{ marginRight: '40px' }}
                                />
                            )}
                        </div>
                    }
                >
                    <AlertTitle>Avoid duplicate customer domains!!!</AlertTitle>
                    Before adding a new customer domain, check if it already exists.
                    <br />
                    For example, progress-ag and progressag are the same.
                    <br />
                    Avoid creating customers twice.
                    <br />
                    If you are unsure, ask someone who has been with Progress for a while and knows the customers.
                    <br />
                    <a
                        href="https://progress-group.atlassian.net/wiki/spaces/PROGRESSGROUP/pages/2772205569/Set+up+a+new+CustomerPortal#1.-Create-a-customer"
                        target="_blank"
                        rel="noreferrer"
                    >
                        See guidelines for customer naming.
                    </a>
                    {similarName?.length && (
                        <>
                            <br />
                            <Alert
                                severity="warning"
                                sx={{ my: '10px', bgcolor: 'rgb(255, 244, 229)' }}
                                variant="outlined"
                                icon=" "
                            >
                                <AlertTitle>The following customers already exist:</AlertTitle>
                                <ul>
                                    {similarName.map((name, index) => (
                                        <li key={index}>{name}</li>
                                    ))}
                                </ul>
                            </Alert>
                        </>
                    )}
                </Alert>
            </div>
        </>
    );
};

export default AddCustomerDomain;
