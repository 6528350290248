import { css } from '@emotion/react';

const styles = css`
    // hide storting order number
    .MuiBadge-standard {
        display: none;
    }

    .no-data-row {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-row {
        margin: 10px 0;
        padding: 15px;
        width: 100%;
    }

    .customToolbar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
    }

    // allow grouping title to expand over other columns
    .MuiDataGrid-groupingCriteriaCell {
        position: absolute;
        width: calc(100% - 20px); //set width in order to avoid horizontal scroll
    }
`;

export default styles;
