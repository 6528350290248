import { RootState } from '@/store/configureStore';
import { ExtApplicationType } from '@/store/data/release/releasesSlice';
import { GitHub } from '@mui/icons-material';
import { ApplicationType, ProgressError, configureTypedThunkCreator, getAppLogo } from '@progress/base-ui';
import AksDashboardImage from '../assets/AksDashboard.png';
import ProgressAIImage from '../assets/ProgressAI.png';
import ZabbixImage from '../assets/Zabbix.png';
import ProgresslogoImage from '../assets/progresslogo.png';
import ProgressWikiImage from '../assets/progresswiki.png';

export const getImage = (applicationType: ExtApplicationType | undefined): React.JSX.Element => {
    let baseAppType: ApplicationType;
    switch (applicationType) {
        case 'AdminCenterYC':
            baseAppType = ApplicationType.ADMINCENTERYC;
            break;
        case 'EbosYC':
        case 'EbosYCProxy':
            baseAppType = ApplicationType.EBOSYC;
            break;
        case 'StabosYCLegacy':
        case 'StabosYC':
        case 'StabosYCProxy':
            baseAppType = ApplicationType.STABOSYC;
            break;
        case 'StabosYCV2Proxy':
            baseAppType = ApplicationType.STABOSYCV2;
            break;
        case 'ProfitYCProxy':
        case 'ProfitYC':
            baseAppType = ApplicationType.PROFITYC;
            break;
        case 'EbosWebAPI':
            baseAppType = ApplicationType.EBOSPXML;
            break;
        case 'ERPbosWebAPI':
            baseAppType = ApplicationType.ERPBOSPXML;
            break;
        case 'MebosYCAPI':
            baseAppType = ApplicationType.MEBOSYCAPI;
            break;
        case 'ProfitV4WebAPI':
            baseAppType = ApplicationType.PROFITPXML;
            break;
        // case "pgadmin":
        //     imageSrc = PgadminImage;
        //     break;
        // case "prometheus":
        //     imageSrc = PrometheusImage;
        //     break;
        // case "influxdb":
        //     imageSrc = InfluxdbImage;
        //     break;
        case 'Ahead':
        case 'AheadProxy':
            baseAppType = ApplicationType.AHEADAPS;
            break;
        case 'MebosYCWebApp':
            baseAppType = ApplicationType.MEBOSYC;
            break;
        case 'ProgressWiki':
            return (
                <img
                    className="app-image"
                    src={ProgressWikiImage}
                    height="25px"
                    alt="ProgressWikiImage"
                />
            );
        case 'AvicadYC':
            baseAppType = ApplicationType.AVICADYC;
            break;
        case 'CustomerPortal':
            baseAppType = ApplicationType.CUSTOMERPORTAL;
            break;
        case 'GitHub':
            return <GitHub height={25} />;
        case 'ProgressDashboard':
            baseAppType = ApplicationType.PROGRESSDASHBOARD;
            break;
        case 'AdvancedPlanningService':
            baseAppType = ApplicationType.ADVANCEDPLANNINGSERVICE;
            break;
        case 'ProgressInsights':
            baseAppType = ApplicationType.PROGRESSINSIGHTS;
            break;
        case 'AksDashboard':
            return (
                <img
                    className="app-image"
                    src={AksDashboardImage}
                    height="25px"
                    alt="AksDashboardImage"
                />
            );
        case 'Zabbix':
            return (
                <img
                    className="app-image"
                    src={ZabbixImage}
                    height="25px"
                    alt="ZabbixImage"
                />
            );
        case 'ProgressAi':
            return (
                <img
                    className="app-image"
                    src={ProgressAIImage}
                    height="25px"
                    alt="ProgressAIImage"
                />
            );
        case 'ConfluenceWiki':
            return (
                <img
                    className="app-image"
                    src={ProgresslogoImage}
                    height="25px"
                    alt="ProgresslogoImage"
                />
            );
        case 'Vision':
            baseAppType = ApplicationType.VISION;
            break;
        case 'PTSManagerYCProxy':
            baseAppType = ApplicationType.PTSMANAGERYC;
            break;
        default:
            return <div>{applicationType}</div>;
    }

    return <div className="app-name">{getAppLogo(baseAppType, { fontSizeInPx: 24 })}</div>;
};

export type ThunkAPIConfig = {
    rejectValue: ProgressError;
    state: RootState;
};
// creates async thunk that, catches the exception and return the rejected error as ProgressError
export const createTypedAsyncThunk = configureTypedThunkCreator<RootState>({});
