import { Log } from '@/helpers/debug';
import * as d3 from 'd3';
import { fabric } from 'fabric';
import { BaseComponent } from './baseComponent';
import { EditableProperty } from './objects';

export class PLCLabel extends BaseComponent {
    controlType = 'plcVar';
    plcValue: string | undefined;
    valueFormatter: string | undefined;
    showHistory: boolean;
    textColor: string | undefined;
    fontSize: number;
    testValue: number | undefined;
    isDirty = false;

    constructor(
        options?: fabric.IRectOptions & {
            plcValue?: string;
            valueFormatter?: string;
            showHistory?: boolean;
            textColor?: string;
            fontSize?: number;
        }
    ) {
        super({
            // defaults
            ...{
                width: 100,
                height: 50,
                fill: '#FFDD00',
                stroke: 'black',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: 'black',
                borderColor: 'black',
            },
            ...options,
        });

        this.fontSize = options?.fontSize ?? 12;
        this.plcValue = options?.plcValue;
        this.valueFormatter = options?.valueFormatter;
        this.showHistory = options?.showHistory ?? false;
        this.textColor = options?.textColor ?? '#000000';
    }

    _render(ctx: CanvasRenderingContext2D) {
        super._render(ctx);
        ctx.font = this.fontSize + 'px sans-serif';
        ctx.fillStyle = this.textColor ?? 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        let text: string | undefined = undefined;
        if (this.testValue && this.valueFormatter) {
            try {
                // eslint-disable-next-line no-eval
                const formatter = eval(this.valueFormatter) as (value: number) => string;
                text = formatter(this.testValue);
            } catch (err) {
                Log.error('[valueFormatter] failed with', err);
                text = 'ERROR';
            }
        }

        ctx.fillText(text ?? 'PLC label', 0, 0);
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true);
        this.isDirty = true;
        this.canvas?.fire('object:modified');
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
            { propertyName: 'fontSize', displayName: 'Font size', type: 'number' },
            { propertyName: 'strokeWidth', displayName: 'Stroke width', type: 'number' },
            { propertyName: 'stroke', displayName: 'Stroke color', type: 'color' },
            { propertyName: 'textColor', displayName: 'Text color', type: 'color' },
            { propertyName: 'fill', displayName: 'Fill color', type: 'color' },
            { propertyName: 'plcValue', displayName: 'PLC variable', type: 'string' },
            { propertyName: 'valueFormatter', displayName: 'Value formatter', type: 'string' },
            { propertyName: 'testValue', displayName: 'Test value', type: 'number' },
            { propertyName: 'showHistory', displayName: 'Show history', type: 'boolean' },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width ?? 0,
            height: this.height ?? 0,
            textColor: this.textColor,
            fontSize: this.fontSize,
            strokeWidth: this.strokeWidth,
            stroke: this.stroke,
            fill: this.fill,
            plcValue: this.plcValue ?? '',
            valueFormatter: this.valueFormatter ?? '',
            showHistory: this.showHistory,
        };

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            };
        } else {
            return {
                ...base,
                top: top,
                left: left,
            };
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append('rect')
            .attr('x', this.left ?? 0)
            .attr('y', this.top ?? 0)
            .attr('width', this.width ?? 0)
            .attr('height', this.height ?? 0)
            .style('fill', (this.fill ?? '') as string)
            .attr('type', this.controlType)
            .attr('textColor', this.textColor ?? 'black')
            .attr('fontSize', this.fontSize)
            .style('stroke-width', this.strokeWidth ?? 1)
            .style('stroke', this.stroke ?? '#000')
            .attr('plcValue', this.plcValue ?? '')
            .attr('valueFormatter', this.valueFormatter ?? '')
            .attr('showHistory', this.showHistory);

        return svgElement;
    }
}
