import { AdditionalFilterOptions } from '@/definitions/AdditionalFilterOptions';
import { useReduxDispatch, useTypedSelector } from '@/store/configureStore';
import { deploymentUIActions } from '@/store/ui/deploymentsUISlice';
import { LoadingButton } from '@mui/lab';
import { Button, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ProgressDialog } from '@progress/base-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTypedTranslation } from '../../definitions';

export const AdditionalFilterDialog = () => {
    const { t } = useTypedTranslation();
    const dispatch = useReduxDispatch();
    const storedAdditionalFilter = useTypedSelector((state) => state.ui.deployments.additionalFilter?.filter);
    const [additionalFilter, setAdditionalFilter] = useState<string>();

    const handleClose = useCallback(() => {
        dispatch(deploymentUIActions.closeAdditionalFilter());
    }, [dispatch]);

    useEffect(() => {
        setAdditionalFilter(storedAdditionalFilter);
    }, [storedAdditionalFilter]);

    const handleApplyFilter = useCallback(() => {
        if (additionalFilter === undefined) {
            dispatch(deploymentUIActions.setAdditionalFilter(AdditionalFilterOptions.No_Additional_Filter));
        } else {
            dispatch(deploymentUIActions.setAdditionalFilter(additionalFilter));
        }
        handleClose();
    }, [dispatch, additionalFilter, handleClose]);

    const handleAdditionalFilter = useCallback((event: SelectChangeEvent) => {
        setAdditionalFilter(event.target.value as string);
    }, []);

    return (
        <ProgressDialog
            title={t('customerList', 'additionalFilters')}
            open
            onClose={handleClose}
            fullWidth
            actions={
                <>
                    <Button
                        variant="text"
                        onClick={handleClose}
                    >
                        {t('main', 'cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        onClick={handleApplyFilter}
                        disabled={!additionalFilter}
                    >
                        {t('customerList', 'apply')}
                    </LoadingButton>
                </>
            }
        >
            <Container>
                <FormControl fullWidth>
                    <InputLabel>{t('customerList', 'additionalFilters')}</InputLabel>
                    <Select
                        fullWidth
                        onChange={handleAdditionalFilter}
                        value={additionalFilter || ''}
                        label={t('customerList', 'additionalFilters')}
                    >
                        <MenuItem value={AdditionalFilterOptions.No_Additional_Filter}>
                            {t('customerList', 'No_Additional_Filter')}
                        </MenuItem>
                        <MenuItem value={AdditionalFilterOptions.Customer_Service_Contract}>
                            {t('customerList', 'Customer_Service_Contract')}
                        </MenuItem>
                        <MenuItem value={AdditionalFilterOptions.MebosAPI_Smartproduction}>
                            {t('customerList', 'MebosAPI_Smartproduction')}
                        </MenuItem>
                        <MenuItem value={AdditionalFilterOptions.SubscriptionValid}>
                            {t('customerList', 'SubscriptionValid')}
                        </MenuItem>
                        <MenuItem value={AdditionalFilterOptions.SubscriptionExpired}>
                            {t('customerList', 'SubscriptionExpired')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Container>
        </ProgressDialog>
    );
};
