/* eslint-disable no-console */
import axios from 'axios';
import { DateTime } from 'luxon';
import { browserName, browserVersion, deviceType, engineName, engineVersion, osName, osVersion } from 'react-device-detect';
import { v4 } from 'uuid';
import { ClientLogEntry, LogType } from '../definitions/autogenerated/types';
import { typedTranslation } from '../definitions/languages';

const FLUSH_INTERVAL_MS = 1000;
const API_PATH = './api/logger/log';
const IS_DEVELOPMENT = import.meta.env.DEV;
const SESSION_ID_KEY = '@sessionId';
const MAX_BUFFER_SIZE = 500;
const MAX_LOG_LENGTH = 5000;
const MAX_ARG_LENGTH = 500;
const LOGGER_META_PREFIX = '[progress-logger]';

/**
 * Retrieves or creates an identifier for the current user session
 */
export const getSessionId = (): string => {
    const currentId = window.sessionStorage.getItem(SESSION_ID_KEY);
    if (currentId) {
        return currentId;
    } else {
        const newId = v4();
        window.sessionStorage.setItem(SESSION_ID_KEY, newId);
        return newId;
    }
};

//Finalized log entry for remote logging
type LogEntry = {
    type: LogType;
    message: string;
    category: string;
};

class RemoteLogBuffer {
    private bufferArr: LogEntry[] = [];

    readonly append = (entry: LogEntry) => {
        this.bufferArr.push(entry);
        //If exceeding buffer limit, remove first (oldest) element (FIFO)
        if (this.bufferArr.length > MAX_BUFFER_SIZE) this.bufferArr = this.bufferArr.slice(1);
    };

    readonly prepend = (entries: LogEntry[]) => {
        //Determine how many old entries can be included in current buffer
        const keep_n = MAX_BUFFER_SIZE - this.bufferArr.length;
        //Keep n most recent old entries and add to current buffer
        const entriesToKeep = entries.slice(Math.max(0, entries.length - keep_n));
        this.bufferArr = [...entriesToKeep, ...this.bufferArr];
    };

    readonly flushToServer = async () => {
        if (this.bufferArr.length > 0) {
            //Snapshot buffer and clear, so that it can be refilled safely during the server await
            const bufferSnapshot = [...this.bufferArr];
            this.bufferArr = [];

            const entries = bufferSnapshot.map((x) => {
                const entry: ClientLogEntry = {
                    Type: x.type,
                    Message: x.message,
                    Category: x.category,
                    SessionId: getSessionId(),
                    Timestamp: getCurrentTimestamp(),
                };
                return entry;
            });

            axios
                .post(API_PATH, entries)
                //If request was not successful, re-add old entries to buffer
                .catchError(() => {
                    this.prepend(bufferSnapshot);
                });
        }
    };
}

const _remoteBuffer = new RemoteLogBuffer();

//Payload for internal handling
type LogPayload = {
    type: LogType;
    args: unknown[];
    category: string | undefined;
};

class LogBehaviour {
    //Mutable internal log behaviour
    log: (payload: LogPayload) => void = (payload: LogPayload) => {
        //Default behaviour:
        //Debug logs are only logged locally in development mode
        //Other logs are always logged locally
        (payload.type !== LogType.Debug || IS_DEVELOPMENT) && logLocal(payload);
    };
}

//Behaviour singleton to modify the behaviour of all loggers during runtime
const _logBehaviour = new LogBehaviour();

//Logging functions require at least one argument in our definition
type LoggingFunc = (primaryArg: unknown, ...optionalArgs: unknown[]) => void;

export class ProgressLogger {
    private readonly category: string | undefined;

    constructor(category: string | undefined) {
        this.category = category;
    }

    //Creates the payload representing the log call, as well as the settings of this logger, and forwards it to the internal behaviour
    private readonly logInternal = (type: LogType, args: unknown[]) => {
        const payload = { type, args, category: this.category };
        _logBehaviour.log(payload);
    };

    /**
     * Log to local console in debug mode.
     * Replaces "console.log" and "console.debug"
     * @param args {@link unknown}
     */
    readonly debug: LoggingFunc = (...args) => this.logInternal(LogType.Debug, args);
    /**
     * Create an Information log, potentially remote.
     * @param args {@link unknown}
     */
    readonly info: LoggingFunc = (...args) => this.logInternal(LogType.Info, args);
    /**
     * Create a Warning log, potentially remote.
     * @param args {@link unknown}
     */
    readonly warn: LoggingFunc = (...args) => this.logInternal(LogType.Warning, args);
    /**
     * Create an Error log, potentially remote.
     * @param args {@link unknown}
     */
    readonly error: LoggingFunc = (...args) => this.logInternal(LogType.Error, args);
    /**
     * Create an Error log if condition is not truthy, potentially remote.
     * @param condition Type-coerced boolean
     * @param args {@link unknown}
     */
    readonly assert: (condition: boolean | undefined | null, primaryArg: unknown, ...optionalArgs: unknown[]) => void = (
        condition,
        ...args
    ) => {
        !condition && this.logInternal(LogType.Error, ['Assertion failed:', ...args]);
    };
    /**
     * Creates a sub-logger with a fixed log prefix
     *
     * Note that any such-created sub-logger simply forwards to the main {@link Logger} singleton and therefore has the same behaviour at runtime
     *
     * @returns A new {@link ProgressLogger}
     */
    readonly createSubcategoryLogger: (category: string) => ProgressLogger = (subCategory) => {
        //If parent (this) has a non-empty category, concatenate it with the sub category, otherwise take the sub category directly if non-empty
        const fullCategory =
            this.category && this.category !== ''
                ? `${this.category}.${subCategory}`
                : subCategory !== ''
                  ? subCategory
                  : undefined;
        return new ProgressLogger(fullCategory);
    };
}

/**
 * {@link ProgressLogger} singleton for remote and local logging
 *
 * If initialized using {@link initLogging}, it will log remotely to the server.
 *
 * If left uninitialized, it behaves identical to the "console" object.
 */
export const Logger = new ProgressLogger(undefined);

const logLocal = (payload: LogPayload) => {
    const caller = callee();
    const args = payload.category
        ? [`[${payload.category + caller}]`, ...payload.args]
        : caller
          ? [`[${caller}]`, ...payload.args]
          : payload.args;
    switch (payload.type) {
        case LogType.Debug:
            console.log(...args);
            break;
        case LogType.Info:
            console.info(...args);
            break;
        case LogType.Warning:
            console.warn(...args);
            break;
        case LogType.Error:
            console.error(...args);
            break;
    }
};

/** only in development mode, returns the name of the calling function, otherwise returns an empty string */
const callee = IS_DEVELOPMENT
    ? (): string => {
          const error = new Error();
          if (!error?.stack) return '';
          // The stack trace is a multi-line string, where each line represents a function call
          const stackLines = error.stack.split('\n');
          // The line of the stack trace represents the calling function
          const stackLineIndex = stackLines.reduceRight((lastIndex, element, index) => {
              return lastIndex === -1 && element.includes('ProgressLogger.') ? index : lastIndex;
          }, -1);
          // If the calling function index is not found (call stack too big?), return an empty string
          if (stackLineIndex === -1) return '';
          const callingFunctionLine = stackLines[stackLineIndex + 1];
          // The name of the calling function is the text between the first set of parentheses
          const functionMatch = callingFunctionLine.match(/at (.*)\(/);
          // If the function name is available, use it
          if (functionMatch && functionMatch.length > 1) return ` -- ${functionMatch[1].trim()}`;
          // If the function name is not available, use the file name
          const fileMatch = callingFunctionLine.match(/([\w]+)\.ts/);
          if (fileMatch && fileMatch.length > 1) return ` -- ${fileMatch[1].trim()}`;
          return '';
      }
    : () => '';

const argToString = (arg: unknown): string => {
    if (typeof arg === 'string') return arg;
    if (typeof arg === 'undefined') return 'undefined';
    if (arg instanceof Error) return arg.toString(); //Error has a meaningful toString() function
    return stringifyCircularJSON(arg);
};

// This allows us to log circular JSON objects
// https://www.30secondsofcode.org/js/s/stringify-circular-json/
const stringifyCircularJSON = (obj: unknown) => {
    const seen = new WeakSet();
    return JSON.stringify(obj, (k, v) => {
        if (v !== null && typeof v === 'object') {
            if (seen.has(v)) return '[Circular]';
            seen.add(v);
        }
        return v;
    });
};

const truncateString = (s: string, max: number): string => (s.length > max ? s.substring(0, max) + '...' : s);

const logRemote = (payload: LogPayload) => {
    const message = payload.args
        .map(argToString)
        .map((x) => truncateString(x, MAX_ARG_LENGTH)) //Truncate individual arguments
        .join(' ');
    //Finally, also truncate whole message
    _remoteBuffer.append({
        type: payload.type,
        category: payload.category ?? '',
        message: truncateString(message, MAX_LOG_LENGTH),
    });
};

const getCurrentTimestamp = () => DateTime.now().toJSDate().toISOString();

/**
 * Creates a server log to register the current user session, providing all relevant client information
 */
const checkin = async () => {
    const { language } = typedTranslation();
    const clientInfo = {
        UserAgent: navigator.userAgent,
        I18NextLanguage: language,
        SupportedLanguages: navigator.languages,
        Browser: `${browserName} ${browserVersion}`,
        OS: `${osName} ${osVersion}`,
        Engine: `${engineName} ${engineVersion}`,
        DeviceType: deviceType,
    };

    const checkinPayload: ClientLogEntry[] = [
        {
            Type: LogType.Info,
            Message: 'Session Start ' + JSON.stringify(clientInfo),
            Category: 'ClientSession',
            SessionId: getSessionId(),
            Timestamp: getCurrentTimestamp(),
        },
    ];

    axios.post(API_PATH, checkinPayload).catchError((e) => {
        console.error(LOGGER_META_PREFIX, 'checkin failed with error: ', e);
    });
};

/**
 * Creates a server log to notify the server that the current user session has ended
 */
const checkout = () => {
    const checkoutPayload: ClientLogEntry[] = [
        {
            Type: LogType.Info,
            Message: 'Session End',
            Category: 'ClientSession',
            SessionId: getSessionId(),
            Timestamp: getCurrentTimestamp(),
        },
    ];

    axios.post(API_PATH, checkoutPayload).catchError((e) => {
        console.error(LOGGER_META_PREFIX, 'checkout failed with error: ', e);
    });
};

//LogType.Debug will never be logged remotely
type RemoteLogType = LogType.Info | LogType.Warning | LogType.Error;

//Initializes the Logger to exclusively produce remote logs
const initProductionLogging = (logLevel: RemoteLogType) => {
    _logBehaviour.log = (payload) => {
        switch (payload.type) {
            case LogType.Debug:
                break;
            case LogType.Info:
                logLevel === LogType.Info && logRemote(payload);
                break;
            case LogType.Warning:
                logLevel !== LogType.Error && logRemote(payload);
                break;
            case LogType.Error:
                logRemote(payload);
        }
    };
};

//Initializes the Logger to print to console and create remote logs
const initProductionLoggingWithDebug = (logLevel: RemoteLogType) => {
    _logBehaviour.log = (payload) => {
        logLocal(payload); //Log everything locally, even debug logs
        switch (payload.type) {
            case LogType.Debug:
                //Never log debug remotely
                break;
            case LogType.Info:
                logLevel === LogType.Info && logRemote(payload);
                break;
            case LogType.Warning:
                //(logLevel === LogType.Info || logLevel === LogType.Warning)
                logLevel !== LogType.Error && logRemote(payload);
                break;
            case LogType.Error:
                //Always log errors remotely
                logRemote(payload);
        }
    };
};

/**
 * Initializes the "Logger" singleton.
 * @param remoteLogLevel Determines the lowest log level that will be sent to the server
 */
export function initLogging(config: { remoteLogLevel: RemoteLogType } = { remoteLogLevel: LogType.Info }) {
    //During development, never log to server, maintain default behaviour
    if (IS_DEVELOPMENT) {
        console.log(LOGGER_META_PREFIX, 'Logger is in development mode, no logs will be sent to server');
    } else {
        //Register periodic flush event
        setInterval(_remoteBuffer.flushToServer, FLUSH_INTERVAL_MS);

        if (window.debugTools.consoleLoggingEnabled) {
            console.warn(
                LOGGER_META_PREFIX,
                `Logger is in production mode, but will also log to console. To disable use 'debugTools.disableConsoleLogging()'.`
            );
            initProductionLoggingWithDebug(config.remoteLogLevel);
        } else {
            console.log(
                LOGGER_META_PREFIX,
                'Logger is in production mode, nothing will be logged to console.',
                `To enable console logging use 'debugTools.enabledConsoleLogging()'.`
            );
            initProductionLogging(config.remoteLogLevel);
        }
    }

    //Perform server checkin
    checkin();

    //Register server checkout event
    window.addEventListener('beforeunload', checkout);
}
