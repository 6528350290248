/* eslint-disable no-console */
import { Log } from '../utils/debug';

declare global {
    interface Window {
        debugTools: DebugTools;
    }
}

type DebugToolsConfig = {
    requestCompressionEnabled: boolean;
    consoleLoggingEnabled: boolean;
};

const DEBUG_TOOLS_KEY = 'debug-tools';
const IS_DEVELOPMENT = import.meta.env.DEV;

const defaultConfig: DebugToolsConfig = {
    requestCompressionEnabled: !IS_DEVELOPMENT,
    consoleLoggingEnabled: IS_DEVELOPMENT,
};

class DebugTools {
    private _requestCompressionEnabled: boolean;
    private _consoleLoggingEnabled: boolean;

    constructor() {
        this._requestCompressionEnabled = defaultConfig.requestCompressionEnabled;
        this._consoleLoggingEnabled = defaultConfig.consoleLoggingEnabled;

        this.loadStoredConfig();
    }

    //#region Getter

    /**
     * Gets the current value indicating if the request compression is enabled
     */
    get requestCompressionEnabled() {
        return this._requestCompressionEnabled;
    }

    /**
     * Gets the current value indicating if the logger should log to the console
     */
    get consoleLoggingEnabled() {
        return this._consoleLoggingEnabled;
    }

    //#endregion

    //#region Local storage

    private saveCurrentConfig() {
        const currentConfig: DebugToolsConfig = {
            consoleLoggingEnabled: this._consoleLoggingEnabled,
            requestCompressionEnabled: this._requestCompressionEnabled,
        };

        localStorage.setItem(DEBUG_TOOLS_KEY, JSON.stringify(currentConfig));
    }

    private loadStoredConfig() {
        const value = localStorage.getItem(DEBUG_TOOLS_KEY);

        if (!value) {
            return;
        }

        try {
            const storedConfig: DebugToolsConfig = JSON.parse(value);
            this._requestCompressionEnabled = storedConfig.requestCompressionEnabled;
            this._consoleLoggingEnabled = storedConfig.consoleLoggingEnabled;
        } catch (err) {
            Log.error('Failed to parse stored config for debug tools. Using default values instead.', err);
        }
    }

    //#endregion

    /**
     * Enables the request compression. NO page reload is required.
     */
    enabledRequestCompression() {
        this._requestCompressionEnabled = true;
        this.saveCurrentConfig();
        console.log('Request compression is now enabled.');
    }

    /**
     * Enables the request compression. NO page reload is required.
     */
    disableRequestCompression() {
        this._requestCompressionEnabled = false;
        this.saveCurrentConfig();
        console.log('Request compression is now disabled.');
    }

    /**
     * Enables logging to the console with the logger. A page reload is required.
     */
    enabledConsoleLogging() {
        this._consoleLoggingEnabled = true;
        this.saveCurrentConfig();
        console.log('Console logging will be enabled after reloading the page.');
    }

    /**
     * Disables logging to the console with the logger. A page reload is required.
     */
    disableConsoleLogging() {
        if (IS_DEVELOPMENT) {
            console.error('Console logging can not be disabled in development!');
            return;
        }
        this._consoleLoggingEnabled = false;
        this.saveCurrentConfig();
        console.log('Console logging will be disabled after reloading the page.');
    }

    /**
     * Prints the instructions in the console to show the babylon inspector.
     */
    showBabylonInspector() {
        console.log('To show the babylon inspector, focus the scene and press CTRL + F9');
    }

    /**
     * Restores the default values for all options
     */
    restoreDefaults() {
        localStorage.removeItem(DEBUG_TOOLS_KEY);
        this._consoleLoggingEnabled = defaultConfig.consoleLoggingEnabled;
        this._requestCompressionEnabled = defaultConfig.requestCompressionEnabled;
        console.log('All defaults have been restored. A page refresh is required.');
    }
}

export function initDebugTools() {
    window.debugTools = new DebugTools();
    console.log("Debug tools are available by using 'debugTools' in the console.");
}
