import {
    GRID_CHECKBOX_SELECTION_FIELD,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
    GridColDef,
    GridPinnedColumnFields,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Log } from '../utils/debug';
import { MuiGridSortModel } from './BaseDataGrid';
import {
    DATAGRID_MOBILE_VIEW_FIELD,
    DataGridColumnOrder,
    DataGridColumnPinning,
    DataGridColumns,
    DataGridColumnSorting,
    DataGridColumnVisibility,
    DataGridColumnWidths,
    DataGridRowGrouping,
    DataGridRowKey,
    DataGridRowType,
    DataGridSortDirection,
    DataGridUIState,
} from './baseDataGridTypes';
import { rowReorderingField } from './useBaseDataGridRowReordering';

/**
 * BaseDataGrid UI state management: column order, visibility, widths, sorting, grouping, pinning
 */
export const useBaseDataGridUIState = <T extends DataGridRowType>(
    gridState: Partial<DataGridUIState<T>> | undefined,
    gridApiRef: MutableRefObject<GridApiPremium>,
    externalColumns: DataGridColumns<T>,
    onGridStateChange: ((state: Partial<DataGridUIState<T>>) => void) | undefined
) => {
    const [internalColumnVisibilityState, setInternalColumnVisibilityState] = useState<DataGridColumnVisibility<T>>({});
    //we alter the column visibility model, it must always be controlled, so we keep an inner state
    const columnVisibilityState = gridState !== undefined ? gridState?.visibility : internalColumnVisibilityState;

    const [internalColumnPinningState, setInternalColumnPinningState] = useState<DataGridColumnPinning<T>>({});
    //we alter the columnpinning model, it must always be controlled, so we keep an inner state
    const columnPinningState = gridState !== undefined ? gridState?.pinning : internalColumnPinningState;

    /** column order changed: build a typed column order object through the api */
    const handleGridColumnOrderChanged = useCallback(() => {
        if (!onGridStateChange) return;
        const order: DataGridColumnOrder<T> = gridApiRef.current.getAllColumns().reduce((result, column, index) => {
            if (column.field.startsWith('__')) return result; // exclude all build in grid fields from the column order
            (result as Record<string, number>)[column.field] = index;
            return result;
        }, {});
        onGridStateChange({ order });
    }, [gridApiRef, onGridStateChange]);

    /** column visibility changed, return a typed column visibility object */
    const handleColumnVisibilityModelChange = useCallback(
        (visibility: DataGridColumnVisibility<T>) => {
            Log.debug('onGridStateChange', { visibility });
            if (onGridStateChange) onGridStateChange({ visibility });
            else setInternalColumnVisibilityState(visibility);
        },
        [onGridStateChange]
    );

    /** column width changed, return a typed column width object omitting the build in grid fields */
    const handleColumnWidthChange = useCallback(() => {
        if (!onGridStateChange) return;
        //Log.debug('columns from api', gridApiRef.current.getAllColumns());
        const columnWidths: DataGridColumnWidths<T> = gridApiRef.current.getAllColumns().reduce((result, column, index) => {
            if (column.field.startsWith('__') || column.flex) return result; // exclude all build in grid fields from the column width
            (result as Record<string, number>)[column.field] = column.width || 0;
            return result;
        }, {});
        Log.debug('onGridStateChange', { width: columnWidths });
        onGridStateChange({ width: columnWidths });
    }, [gridApiRef, onGridStateChange]);

    /** column sorting conversion */
    const gridSorting = useMemo(() => {
        if (!gridState?.sorting) return undefined;
        return Object.entries(gridState.sorting).map(([field, sort]) => ({
            field: field,
            sort: sort,
        }));
    }, [gridState?.sorting]);

    /** column sorting changed, build a typed column sorting object, omitting all null values */
    const handleSortModelChange = useCallback(
        (model: MuiGridSortModel<T>) => {
            if (!onGridStateChange) return;
            const sorting: DataGridColumnSorting<T> = model.reduce((result, sort) => {
                if (sort.sort) (result as Record<keyof T, DataGridSortDirection>)[sort.field] = sort.sort;
                return result;
            }, {});
            Log.debug('onGridStateChange', { sorting });
            onGridStateChange({ sorting });
        },
        [onGridStateChange]
    );

    /** row grouping conversion */
    const gridGrouping = useMemo(() => {
        if (!gridState?.grouping) return undefined;
        return Object.entries(gridState.grouping).map(([field, index]) => field);
    }, [gridState?.grouping]);

    /** row grouping changed, build a typed row grouping object with field name and grouping index */
    const handleRowGroupingModelChange = useCallback(
        (model: Array<keyof T>) => {
            if (!onGridStateChange) return;
            const grouping: DataGridRowGrouping<T> = (model ?? {}).reduce((record, key, index) => {
                record[key as keyof T] = index;
                return record;
            }, {} as DataGridRowGrouping<T>);
            Log.debug('onGridStateChange', { grouping });
            onGridStateChange({ grouping });
        },
        [onGridStateChange]
    );

    /** the action columns must always be pinned right */
    const actionColumns = useMemo(() => {
        return externalColumns.columns.filter((column) => column._columnType === 'action').map((column) => column.field);
    }, [externalColumns]);

    const gridPinning = useMemo(() => {
        const gridPinning: GridPinnedColumnFields = {};
        if (columnPinningState && columnPinningState.left) {
            gridPinning.left = Object.entries(columnPinningState.left).map(([field, index]) => field);
            //  NB: we would want to pin the row grouping column to the very left, but the mui datagrid behaves strangely
            //   when the column is pinned first, then grouped (doubles the column). In the future, we might want to try to fix this.
            //   gridPinning.left.unshift(GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD);
        }
        if (columnPinningState && columnPinningState.right) {
            gridPinning.right = Object.entries(columnPinningState.right).map(([field, index]) => field);
        }
        if (actionColumns.length > 0) gridPinning.right = [...(gridPinning.right ?? []), ...actionColumns];
        return gridPinning;
    }, [actionColumns, columnPinningState]);

    /** column pinning changed */
    const handlePinnedColumnsChange = useCallback(
        (pinnedColumns: GridPinnedColumnFields) => {
            Log.debug('onGridStateChange', { pinning: pinnedColumns });
            const pinning: DataGridColumnPinning<T> = {
                left: (pinnedColumns.left ?? []).reduce(
                    (result, field, index) => {
                        result[field as keyof T] = index;
                        return result;
                    },
                    {} as Record<keyof T, number>
                ),
                right: (pinnedColumns.right ?? []).reduce(
                    (result, field, index) => {
                        if (actionColumns.includes(field)) return result;
                        result[field as keyof T] = index;
                        return result;
                    },
                    {} as Record<keyof T, number>
                ),
            };
            if (onGridStateChange) onGridStateChange({ pinning });
            else setInternalColumnPinningState(pinning);
        },
        [actionColumns, onGridStateChange]
    );

    const columnVisibility: DataGridColumnVisibility<T> = useMemo(() => {
        const result: DataGridColumnVisibility<T> = structuredClone(columnVisibilityState ?? {});
        if (gridGrouping)
            //also hide grouping columns
            gridGrouping.forEach((group) => {
                result[group as DataGridRowKey<T>] = false;
            });
        return result;
    }, [columnVisibilityState, gridGrouping]);

    /** hide special columns from toggle columns menu */

    const getTogglableColumns = useCallback(
        (columns: GridColDef[]) => {
            const result = columns
                .filter(
                    (column) =>
                        ![
                            GRID_CHECKBOX_SELECTION_FIELD,
                            GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
                            GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
                            DATAGRID_MOBILE_VIEW_FIELD,
                            rowReorderingField,
                            ...(gridGrouping ?? []),
                            ...(actionColumns ?? []),
                        ].includes(column.field)
                )
                .map((column) => column.field);
            return result;
        },
        [actionColumns, gridGrouping]
    );

    return {
        handleGridColumnOrderChanged,
        handleColumnVisibilityModelChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleRowGroupingModelChange,
        handlePinnedColumnsChange,
        getTogglableColumns,
        gridSorting,
        gridGrouping,
        gridPinning,
        columnVisibility,
    };
};
