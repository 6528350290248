import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';
import { FetchError, default as SVG } from 'react-inlinesvg';

interface SvgViewerProps {
    svgContainerId: string;
    svgId?: string;
    svgClassName?: string;
    onLoad?: () => void;
    onError?: (error: Error | FetchError) => void;
    style?: React.CSSProperties;
}

/**
 * source components to provide information for SvgFromRawSource
 */
export interface SvgFromRawSourceProps extends SvgViewerProps {
    svgSource: string;
}

const SvgContainer = styled('div')({
    '& > svg > g:focus': {
        outline: 'none',
    },
});

/**
 * Display a SVG file inside of a react component
 *
 * @returns ForwardRefExoticComponent containing the provided SVG
 */
export const SvgFromRawSource = forwardRef<SVGElement, SvgFromRawSourceProps>(function SvgFromRawSource(props, ref) {
    return (
        <SvgContainer id={props.svgContainerId}>
            <SVG
                innerRef={ref}
                src={props.svgSource}
                onError={props.onError}
                onLoad={props.onLoad}
                id={props.svgId}
                className={props.svgClassName}
                style={props.style}
                cacheRequests
            ></SVG>
        </SvgContainer>
    );
});
