import { AdditionalFilterOptions } from '@/definitions/AdditionalFilterOptions';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfigAssemblyMapping } from '../../definitions/autogenerated/assemblyMappings';
import { ExtApplicationType } from '../data/release/releasesSlice';

type AppToUpdate = {
    type: ExtApplicationType;
    appIdentifier: string;
    appType: 'global' | 'non-global';
    currentVersion?: string;
};

type AppToDelete = {
    kind: 'app';
    type: ExtApplicationType;
    appIdentifier: string;
};

type CustomerToDelete = {
    kind: 'customer';
    name: string;
};

type AppToEdit = {
    type: keyof typeof IConfigAssemblyMapping;
    appIdentifier: string;
};

type AdditionalFilter = {
    filter: string;
    open: boolean;
};

type DeploymentUISlice = {
    filterModel: GridFilterModel;
    appToUpdate: AppToUpdate | undefined;
    deploymentToDelete: AppToDelete | CustomerToDelete | undefined;
    appToEdit: AppToEdit | undefined;
    additionalFilter: AdditionalFilter;
};

const initialState: DeploymentUISlice = {
    filterModel: {
        items: [],
    },
    appToUpdate: undefined,
    deploymentToDelete: undefined,
    appToEdit: undefined,
    additionalFilter: {
        filter: AdditionalFilterOptions.No_Additional_Filter,
        open: false,
    },
};

export const deploymentUISlice = createSlice({
    name: 'deploymentUI',
    initialState: initialState,
    reducers: {
        setFilterModel: (state, action: PayloadAction<GridFilterModel>) => {
            state.filterModel = action.payload;
        },
        setAppToUpdate: (state, action: PayloadAction<AppToUpdate | undefined>) => {
            state.appToUpdate = action.payload;
        },
        setDeploymentToDelete: (state, action: PayloadAction<DeploymentUISlice['deploymentToDelete']>) => {
            state.deploymentToDelete = action.payload;
        },
        setAppToEdit: (state, action: PayloadAction<DeploymentUISlice['appToEdit']>) => {
            state.appToEdit = action.payload;
        },
        setAdditionalFilter: (state, action: PayloadAction<string>) => {
            state.additionalFilter.filter = action.payload;
        },
        openAdditionalFilter: (state) => {
            state.additionalFilter.open = true;
        },
        closeAdditionalFilter: (state) => {
            state.additionalFilter.open = false;
        },
    },
});

export const deploymentUIActions = deploymentUISlice.actions;
