import { CSSProperties, forwardRef, ReactElement } from 'react';
import { useResize } from '../hooks/useResize';

/** A type that represents a React ref object or a ref callback function, or null. */
type UnknownRef = React.MutableRefObject<unknown> | React.RefCallback<unknown> | null;

/**
 * Merges multiple refs into a single ref callback function that can be passed to a React element.
 * When the callback is called with an instance, it sets the instance on each ref.
 *
 * @param refs - The refs to merge.
 * @returns A single ref callback that sets the instance on each ref.
 */
export function mergeRefs(...refs: UnknownRef[]) {
    return (instance: unknown) => {
        refs.forEach((ref) => {
            // Iterate over the refs
            if (typeof ref === 'function') {
                // If the ref is a function, call it with the instance
                ref(instance);
            } else if (ref != null) {
                // If the ref is not null, set the current property of the ref to the instance
                ref.current = instance;
            }
        });
    };
}

/** Props for a `FillFlexParent` component. */
type FillFlexParentProps = {
    /** A function that returns React elements. The function is called with an object containing the dimensions of the parent element. */
    children: (dimensions: { width: number; height: number }) => ReactElement;
    style?: CSSProperties;
};

/**
 * A React component that fills its parent element and passes the dimensions of the parent element to its children as a prop.
 *
 * @param props - The props for the component.
 * @param forwardRef - A ref forwarded to the inner `div` element.
 * @returns The rendered component.
 */
export const FillFlexParent = forwardRef(function FillFlexParent(props: FillFlexParentProps, forwardRef) {
    const [ref, { width, height }] = useResize(); // Get a ref and the dimensions of the element that the ref is attached to
    return (
        // Render a div that fills its parent element, and pass the ref and the forwardRef to the div
        <div
            style={{ display: 'flex', width: '100%', minHeight: 0, minWidth: 0, ...props.style }}
            ref={mergeRefs(ref, forwardRef)}
        >
            {/* Render the children if the width and height are defined, otherwise return null */}
            {width && height ? props.children({ width, height }) : null}
        </div>
    );
});
