import { CircularProgress } from '@mui/material';
import {
    ErrorBoundary,
    PDFPageRotation,
    clamp,
    debouncePerFrame,
    usePdfConverter,
    useResize,
    useUpdateEffect,
} from '@progress/base-ui';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTypedTranslation } from '../definitions/languages';
import { useDebouncedState } from '../hooks/useDebouncedState';
import { Log } from '../utils/debug';
import { PDFPages, PDFPagesHandle, PDFPagesProps } from './PDFPages';
import PDFViewerPagination from './PDFViewerPagination';
import PDFViewerToolbar from './PDFViewerToolbar';
import { ZoomPan, ZoomPanHandle } from './ZoomPan';
import { usePDFPageSizes } from './pdfViewerHooks';
import { PDFViewerContainer, pdfViewerClass } from './pdfViewerStyles';
import { PDFHtmlElementSize, PDFPageFit, PDFViewerHandle, PDFViewerProps } from './pdfViewerTypes';

/** wrapper to provide error boundary to the pdf viewer */
export const PDFViewer = forwardRef<PDFViewerHandle, PDFViewerProps>((props, pdfViewerRef) => {
    const [containerRef, containerSize] = useResize(); //track the container size, will be 0/0 on first render

    return (
        //enclose within ErrorBoundary to catch exceptions before they bubble to the parent application
        <ErrorBoundary>
            <PDFViewerContainer
                ref={containerRef}
                {...pdfViewerClass('pages-container')}
            >
                {containerSize.width > 0 &&
                    containerSize.height > 0 && ( //ensure rendering only if container size is defined
                        <PDFViewerBase
                            ref={pdfViewerRef}
                            containerSize={containerSize}
                            {...props}
                        />
                    )}
            </PDFViewerContainer>
        </ErrorBoundary>
    );
});

type PDFViewerBaseProps = PDFViewerProps & {
    containerSize: PDFHtmlElementSize;
};

/** the base pdf viewer with events context and error boundary */
const PDFViewerBase = forwardRef<PDFViewerHandle, PDFViewerBaseProps>(function PDFViewerBase(
    {
        pdfIdentity,
        pdfData,
        pagesPerRow = 1,
        pageGap = 10,
        pageFit = 'width',
        pageRotation = 0,
        pageZoom = 1,
        pageNumber = 1,
        singlePageView = false,
        minZoom = 0.01,
        maxZoom = 8,
        parentLoading,
        noPdfDataMessage,
        containerSize,
        //component slots
        toolbar: toolbarProps = { visible: true, component: PDFViewerToolbar },
        pagination: paginationProps = { visible: true, component: PDFViewerPagination },
    },
    ref
) {
    const { t } = useTypedTranslation();

    // Provide default values for nested properties of toolbar and pagination
    const { visible: showToolbar = true, features: toolbarFeatures, component: Toolbar = PDFViewerToolbar } = toolbarProps;

    const {
        visible: showPagination = singlePageView, //for the pagination really to show, the single page view must be enabled
        component: Pagination = PDFViewerPagination,
    } = paginationProps;

    const initialPageNumber = Math.max(1, pageNumber);
    const initialPageZoom = pageFit === 'custom' ? pageZoom : 1;
    /** the currentPage only affects the single page mode. in multi page mode, it will be always 1, and only the pageNumber prop will be used */
    const [currentPage, setCurrentPage] = useState<number>(singlePageView ? initialPageNumber : 1);

    const [currentPageFit, setCurrentPageFit] = useState<PDFPageFit>(pageFit);

    const [currentPageRotation, setCurrentPageRotation] = useState(pageRotation);

    const [currentPageZoom, setCurrentPageZoom] = useDebouncedState(clamp(initialPageZoom, minZoom, maxZoom), 200);
    /** the parent container size is saved in state and mutated only if a recalculation is needed */
    const [currentContainerSize, setCurrentContainerSize] = useState<PDFHtmlElementSize>(containerSize);
    /** the current pdf identity is used to determine if the pdf document _source_ has changed. Generated PDFs will always have a new documentId, we cannot rely on it */
    const [currentPdfIdentity, setCurrentPdfIdentity] = useState<string | undefined>();

    const { loadPDFDocument, closePDFDocument, pdfDocumentInfo, pdfDocumentLoading, pdfPageRendersFirstTime, renderPDFPage } =
        usePdfConverter();

    const onlyShowPage = singlePageView && (pdfDocumentInfo?.pageCount ?? 0) >= currentPage ? currentPage - 1 : undefined;

    const { pagesWrapperSize, pagesContentSizes } = usePDFPageSizes({
        containerSize: currentContainerSize,
        unscaledPageSizes: pdfDocumentInfo?.pageSizes ?? [],
        pagesPerRow,
        pageGap,
        pageFit: currentPageFit,
        pageRotation: currentPageRotation,
        onlyShowPage,
    });

    const pdfPagesRef = useRef<PDFPagesHandle>(null);
    /** ensure the gridViewer is initialized */
    const pdfPages = useCallback(() => {
        if (!pdfPagesRef.current) throw new Error('PDFPages are not initialized.');
        return pdfPagesRef.current;
    }, [pdfPagesRef]);

    const zoomPanRef = useRef<ZoomPanHandle>(null);
    /** ensure the zoomPanScroll is initialized */
    const zoomPan = useCallback(() => {
        if (!zoomPanRef.current) throw new Error('ZoomPanScroll is not initialized.');
        return zoomPanRef.current;
    }, [zoomPanRef]);

    //we need to initialize an internal ref to the pdfViewer, since we cannot guarantee a forwardRef is set.
    const internalPdfViewerRef = useRef<PDFViewerHandle>(null);
    const pdfViewerRef = ref || internalPdfViewerRef; //use the forwardRef if provided, otherwise use the internal ref
    /** ensure the pdfViewer is initialized */
    const pdfViewer = useCallback(() => {
        if (pdfViewerRef !== null && typeof pdfViewerRef === 'object' && pdfViewerRef.current) return pdfViewerRef.current;
        throw new Error('PDFViewer is not initialized');
    }, [pdfViewerRef]);

    useImperativeHandle(pdfViewerRef, () => ({
        setPageNumber: (page) => {
            const pageCount = pdfDocumentInfo?.pageCount ?? 0;
            if (singlePageView) {
                pdfViewer().resetView(true);
                setCurrentPage(pageCount === 0 ? 1 : clamp(page, 1, pageCount));
                //setShowRenderLoader(true)
            } else if (page && (pdfDocumentInfo?.pageCount ?? 0 >= page)) {
                const pageElement = pdfPages().getPageElement(page - 1);
                if (pageElement) {
                    //the setPan function will automatically avoid scrolling too far and will keep the pages in view
                    zoomPan().setPan({
                        x: -pageElement.offsetLeft * currentPageZoom,
                        y: -pageElement.offsetTop * currentPageZoom,
                    });
                }
                //when no pageElement with that index is found, it will stay on page 1
            }
        },
        setPageFit: (mode) => {
            setCurrentPageFit(mode);
            zoomPan().setZoom(1); //reset pan and zoom to zoom value from props
            setCurrentContainerSize(containerSize); //mutate the container size to cause recalculation
        },
        resetView: (resetRotation = true, resetPan = true, resetPage = true) => {
            zoomPan().setZoom(initialPageZoom ?? 1); //reset zoom to zoom value from props
            resetPan && zoomPan().setPan({ x: 0, y: 0 }); //reset pan to (0,0)
            resetRotation && setCurrentPageRotation(pageRotation); //reset rotation to rotation value from props
            setCurrentPageFit(pageFit); //reset fit to fit value from props
            setCurrentContainerSize(containerSize); //reset container size to container size from props, will cause mutation and recalculation
            resetPage && !singlePageView && pdfViewer().setPageNumber(initialPageNumber); //reset page number to page number from props, will scroll to page
        },
        setPageRotation: (rotation?: PDFPageRotation) => {
            rotation !== undefined
                ? setCurrentPageRotation(rotation)
                : setCurrentPageRotation(((currentPageRotation + 90) % 360) as PDFPageRotation);
        },
        setPageZoom: zoomPan().setZoom,
        zoomIn: zoomPan().zoomIn,
        zoomOut: zoomPan().zoomOut,
    }));

    /** open, load and close the PDF document */
    useEffect(() => {
        async function loadPdfData() {
            if (!pdfData) {
                closePDFDocument();
                return; // Early return if no PDF data is provided
            }
            const currentPan = zoomPan().getPan(); //we save the current pan to restore it after the document is loaded
            const documentInfo = await loadPDFDocument(pdfData); //loading with function from hook, the "pdfDocumentInfo" is updated by the hook, we just use the return value directly to set the identity
            const documentIdentity = pdfIdentity ?? documentInfo?.documentId; //the pdfIdentity from props or the documentId from the PDF document (which always changes for generated PDFs)
            //it is crucial we set the pdf identity after the document is loaded, otherwise the reset of the zoomPanScroll will fire too early
            setCurrentPdfIdentity(documentIdentity); //this set here will cause an INSTANT render, even before the async function is exited
            if (currentPdfIdentity === documentIdentity)
                //at this point the render with the new identity is already done!
                zoomPan().setPan(currentPan); //restore the pan after the document is loaded, when the identity is the same
        }
        loadPdfData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfData]);

    /** reset the zoom after the document is loaded, when the identity changed */
    useUpdateEffect(() => {
        pdfViewer().resetView(true);
        if (singlePageView) setCurrentPage(clamp(initialPageNumber, 1, pdfDocumentInfo?.pageCount ?? 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPdfIdentity]);

    /** reset the page number if the number of pages has changed */
    useUpdateEffect(() => {
        if (!singlePageView) return;
        if (!pdfDocumentInfo || currentPage > pdfDocumentInfo.pageCount) setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfDocumentInfo?.pageCount]);

    /** when the pageNumber prop changes, we need to set the inner state pageNumber */
    useUpdateEffect(() => {
        pdfViewer().setPageNumber(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, singlePageView]);

    /** when the pageFit prop changes, we need to retrigger a rerender, we set the pageFit state, and handleRenderCell will mutate */
    useUpdateEffect(() => {
        pdfViewer().setPageFit(pageFit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageFit]);

    /** when the container size or the pagesWrapperSize (the wrapper around the pages) changes, we need to keep the zoomPan content centered and in view */
    useUpdateEffect(() => {
        zoomPan().adjustToContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerSize.width, containerSize.height, pagesWrapperSize]);

    /** debounce the zoom and pan changes, the event will fire very often in sequence. Without debounce it will cause performance issues */
    const adjustContentDebounced = debouncePerFrame(() => zoomPan().adjustToContent());

    /** when the zoom changes, we need to retrigger a rerender, we set the zoom state, and handleRenderCell will mutate */
    const handleOnZoomChanged = useCallback(
        (scale: number) => {
            setCurrentPageZoom(scale);
            adjustContentDebounced();
        },
        [adjustContentDebounced, setCurrentPageZoom]
    );

    /** when the scoll position changed, we need adjust the zoomPan content */
    const handleOnPanChanged = useCallback(
        (x: number, y: number) => {
            adjustContentDebounced();
        },
        [adjustContentDebounced]
    );

    /** page render callback - if it mutates (deps of useCallback change), will trigger a re-render internally */
    const handleRenderPage: PDFPagesProps['renderPage'] = useCallback(
        async (pageIndex, pageSize, contentSize) => {
            const renderedPage = await renderPDFPage(
                pageIndex + 1,
                (pageSize.width / contentSize.width) * currentPageZoom,
                currentPageRotation
            );
            return renderedPage.canvas;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps -- mutating the handleRenderCell callback will trigger a re-render
        [renderPDFPage, currentPageZoom, currentPageRotation, currentPageFit, singlePageView ? 1 : pagesPerRow, pageGap]
    );

    /** pagination component callback - this must be in singlePageView mode */
    const handlePaginationChange = useCallback(
        (event: React.ChangeEvent<unknown>, page: number) => {
            pdfViewer().setPageNumber(page);
        },
        [pdfViewer]
    );

    Log.debug('[Render] PDFViewer', { containerSize, pagesContentSizes, currentContainerSize, pdfDocumentInfo });

    return (
        <>
            <ZoomPan
                ref={zoomPanRef}
                minScale={minZoom}
                maxScale={maxZoom}
                onPanChanged={handleOnPanChanged}
                onZoomChanged={handleOnZoomChanged}
            >
                <PDFPages
                    ref={pdfPagesRef}
                    pagesPerRow={singlePageView ? 1 : Math.max(1, pagesPerRow)}
                    pageGap={pageGap}
                    wrapperSize={pagesWrapperSize}
                    pageSizes={pagesContentSizes}
                    renderPage={handleRenderPage}
                />
            </ZoomPan>
            {
                // show toolbar only if the document is loaded
                showToolbar && (
                    <Toolbar
                        pdfViewer={pdfViewer}
                        features={toolbarFeatures}
                        disabled={pdfDocumentLoading || parentLoading || !pdfDocumentInfo}
                        documentInfo={pdfDocumentInfo}
                    />
                )
            }
            {
                // show pagination only if the document is loaded
                showPagination &&
                    singlePageView &&
                    !pdfDocumentLoading &&
                    !parentLoading &&
                    currentPage !== undefined &&
                    pdfDocumentInfo &&
                    pdfDocumentInfo?.pageCount > 1 && (
                        <Pagination
                            pdfViewer={pdfViewer}
                            currentPage={currentPage}
                            documentInfo={pdfDocumentInfo}
                            onSwitchPage={handlePaginationChange}
                        />
                    )
            }
            {
                // show loading indicator
                (pdfDocumentLoading || parentLoading || pdfPageRendersFirstTime) && (
                    <div {...pdfViewerClass('document-loading')}>
                        <CircularProgress disableShrink />
                    </div>
                )
            }
            {
                // show no documents message
                !pdfDocumentLoading && !parentLoading && !pdfData && (
                    <div {...pdfViewerClass('no-document')}>
                        <span {...pdfViewerClass('no-document-text')}>{noPdfDataMessage ?? t('pdfViewer', 'noDocuments')}</span>
                    </div>
                )
            }

            {
                // show no pages message
                !pdfDocumentLoading && !parentLoading && pdfData && pdfDocumentInfo && pdfDocumentInfo.pageCount === 0 && (
                    <div {...pdfViewerClass('no-pages')}>
                        <span {...pdfViewerClass('no-pages-text')}>{t('pdfViewer', 'noPages')}</span>
                    </div>
                )
            }
        </>
    );
});
