import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD, GridColDef } from '@mui/x-data-grid-premium';
import { DateTime } from 'luxon';
import { TypedOmit } from '../definitions/Partials';

export const DATAGRID_MOBILE_VIEW_FIELD = '__datagrid_mobile_view__';
/** definition for the branded datagrid row model */
export type DataGridRowType = Record<string, unknown>;
/** definition of the expected rows type. This is generated by useDataGridRows */
export type DataGridRows<T extends DataGridRowType> = { rows: T[]; _origin_: 'useDataGridRows' };
/** definition for the column type, must only be used by the builder internally */
export type DataGridColumnType = 'primitive' | 'object' | 'dateTime' | 'action' | 'detail' | 'reorder';
/** Branded type for DataGrid columns - incompatible with GridColDef on purpose to enforce using the builder */
export type DataGridColDef<T extends DataGridRowType> = GridColDef<T> & { _columnType: DataGridColumnType };
/** definition of the expected rows type. This is generated by useDataGridRows */
export type DataGridColumns<T extends DataGridRowType> = { columns: DataGridColDef<T>[]; _origin_: 'useDataGridColumns' };
/** key type for row type keys - must be intersected with string, otherwise will be inferred as string | symbol | number */
export type DataGridRowKey<T extends DataGridRowType> = keyof T & string;
/** the datagrid row id must be a string - it is not compatible to GridRowId! */
export type DataGridRowId = string;
/** column sort direction, ascending or descending */
export type DataGridSortDirection = 'asc' | 'desc';
/** alignment types in cells and headers */
export type DataGridAlignment = 'left' | 'right' | 'center';
/** density types in cells and headers */
export type DataGridDensity = 'compact' | 'standard';
/** row selection types */
export type DataGridRowSelectionType = 'single' | 'multiple' | 'none';
/** define what is considered a primitive value */
export type DataGridPrimitiveValue = string | number | boolean | null | undefined;
/** typed definition for the column visibility, key is the field name, value is the visibility */
export type DataGridColumnVisibility<T extends DataGridRowType> = Partial<
    Record<DataGridRowKey<T> | typeof GRID_DETAIL_PANEL_TOGGLE_FIELD, boolean>
>;
/** typed definition for the column order, key is the field name, value is the order */
export type DataGridColumnOrder<T extends DataGridRowType> = Partial<Record<keyof T, number>>;
/** typed definition for the column width, key is the field name, value is the width in pixels */
export type DataGridColumnWidths<T extends DataGridRowType> = Partial<Record<keyof T, number>>;
/** typed definition for the column sort, key is the field name, value is the sort direction */
export type DataGridColumnSorting<T extends DataGridRowType> = Partial<
    Record<keyof T | typeof GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD | typeof DATAGRID_MOBILE_VIEW_FIELD, DataGridSortDirection>
>;
/** typed definition for the row grouping, key is the field name, value is the grouping order */
export type DataGridRowGrouping<T extends DataGridRowType> = Partial<Record<keyof T, number>>;
/** typed definition for the column map, ensuring every provided row type extends DataGridRowType */
export type DataGridValidColumnsMap<M extends Record<string, DataGridRowType>> = M;

/** typed definition for the column pinning */
export type DataGridColumnPinning<T extends DataGridRowType> = {
    left?: DataGridColumnOrder<T>;
    right?: DataGridColumnOrder<T>;
};
/** The function to retrieve the id of a DataGridRowType. */
export type DataGridGetRowId<T extends DataGridRowType> = (row: T) => DataGridRowId;
/** typed definition for the datagrid state, containing the visibility, order, width, sorting, grouping and pinning. */
export type DataGridUIState<T extends DataGridRowType> = {
    visibility?: DataGridColumnVisibility<T>;
    order?: DataGridColumnOrder<T>;
    width?: DataGridColumnWidths<T>;
    sorting?: DataGridColumnSorting<T>;
    grouping?: DataGridRowGrouping<T>;
    pinning?: DataGridColumnPinning<T>;
};
/** typed definition for the state change function. will be triggered if any of the state properties change. */
export type DataGridStateChange<T extends DataGridRowType> = (state: Partial<DataGridUIState<T>>) => void;
/** types for grid formatted value, with generic raw value and formatted string */
export type DataGridFormattedValue<T> = { value: T; formatted: string };
/** typed definition for the formatted date, must be preprocessed */
export type DataGridFormattedDate = DataGridFormattedValue<DateTime | null>;
/** typed definition for the common cell render parameters. The value is the raw value type. */
export type DataGridColumnCallbackParams<T extends DataGridRowType, K extends DataGridRowKey<T>> = {
    field: K;
    row: T;
    value: T[K];
};
/** typed definition for the group cell render parameters. The value in this case is the formatted string. */
export type DataGridDateTimeRenderGroupParams<T extends DataGridRowType, K extends DataGridRowKey<T>> = {
    field: K;
    row: T;
    value: string;
};
/** typed definition for the datetime group cell renderer, using the render group params for datetime. */
export type DataGridDateTimeRenderGroup<T extends DataGridRowType, K extends DataGridRowKey<T>> = (
    params: DataGridDateTimeRenderGroupParams<T, K>
) => React.ReactNode;

/** typed definition for the common cell render parameters */
export type DataGridRenderCellParams<T extends DataGridRowType, K extends DataGridRowKey<T>> = DataGridColumnCallbackParams<T, K>;
/** typed definition for the mobile cell render parameters */
export type DataGridMobileRenderParams<T extends DataGridRowType> = {
    row: T;
};
/** typed definition for the common cell renderer, using the common render params */
export type DataGridRenderCell<T extends DataGridRowType, K extends DataGridRowKey<T>> = (
    params: DataGridRenderCellParams<T, K>
) => React.ReactNode;

/** typed definition for the mobile cell render parameters */
export type DataGridMobileRenderCell<T extends DataGridRowType> = (params: DataGridMobileRenderParams<T>) => React.ReactNode;

/** typed definition for the common caption renderer, at the moment not using any params */
export type DataGridRenderCaption = () => React.ReactNode;

export type DataGridSortCompare<T extends DataGridRowType, K extends DataGridRowKey<T>> = (value1: T[K], value2: T[K]) => number;

/** typed definition for the action column render parameters. Action columns have no value, but carry the rowId. */
export type DataGridActionColumnCallbackParams<T extends DataGridRowType, K extends DataGridRowKey<T>> = {
    field: K;
    row: T;
    rowId: DataGridRowId;
};
/** typed definition for the cell render parameters of action columns containing  */
export type DataGridActionRenderValueParams<
    T extends DataGridRowType,
    K extends DataGridRowKey<T>
> = DataGridActionColumnCallbackParams<T, K>;
/** typed definition for the cell renderer of action columns, using the action render params */
export type DataGridActionRenderCell<T extends DataGridRowType> = <K extends DataGridRowKey<T>>(
    params: DataGridActionRenderValueParams<T, K>
) => React.ReactNode;

/** typed definition for the common value getter parameters */
export type DataGridGetValueParams<T extends DataGridRowType, K extends DataGridRowKey<T>> = DataGridColumnCallbackParams<T, K>;
/** typed definition for the common value getter */
export type DataGridGetValue<T extends DataGridRowType> = <K extends DataGridRowKey<T>>(
    params: DataGridGetValueParams<T, K>
) => DataGridPrimitiveValue;
/** typed definition for the cell class getter */
export type DataGridGetCellClassName<T extends DataGridRowType> = <K extends DataGridRowKey<T>>(
    params: DataGridColumnCallbackParams<T, K>
) => string | undefined;

export type DataGridColumnProps = Partial<{
    /** the column caption, displayed in the header of the column. if not set, no caption will be displayed. */
    caption: string;
    /** the column tooltip, displayed when hovering the column header. If not set, the caption will be used. */
    toolTip: string;
    /** the column width in pixels, will be overridden by flexWidth, if set */
    width: number;
    /** the column flex width in fractions, overrides width */
    flexWidth: number;
    /** the absolute minimum width of the column in pixels */
    minWidth: number;
    /** the absolute maximum width of the column in pixels */
    maxWidth: number;
    /** can the column be edited @default false */
    editable: boolean;
    /** can the column be hidden through the column menu @default true */
    hideable: boolean;
    /** can the column be sorted by clicking the header @default true */
    sortable: boolean;
    /** can the column be grouped through the column menu @default true */
    groupable: boolean;
    /** does the column value export @default true */
    exportable: boolean;
    /** can the column be pinned through the column menu @default true */
    pinnable: boolean;
    /** can the column be filtered @default true */
    filterable: boolean;
    /** can the column content be searched through the quick filter @default true */
    searchable: boolean;
    /** can the column be reordered @default true */
    reorderable: boolean;
    /** can the column be resized @default true */
    resizable: boolean;
    /** can the column be aggregated @default true */
    aggregable: boolean;
    /** wether to show the column menu @default true */
    showMenu: boolean;
    /** the class name for the cells of this column */
    className: string;
    /** The direction of the sorting @default null */
    sorting: DataGridSortDirection;
    /** The order of the sorting sequence. */
    sortingOrder: (DataGridSortDirection | null)[];
    /** The cell content alignment */
    align: DataGridAlignment;
    /** The header cell content alignment */
    alignCaption: DataGridAlignment;
    /** The number of columns that should be merged */
    span: number;
}>;

/** Column callbacks, depend on both T and K. This ensures getValue is tied to a specific field type. */
export type DataGridColumnCallbacks<T extends DataGridRowType, K extends DataGridRowKey<T>> = Partial<{
    /** getValue when the field is in "leaf" row type */
    getValue: (params: DataGridColumnCallbackParams<T, K>) => DataGridPrimitiveValue;
    /** getValue when the field is in "group" row type. falls back to getValue if not set. */
    getGroupValue: (params: DataGridColumnCallbackParams<T, K>) => DataGridPrimitiveValue;
    /** render the data cell, only called in "leaf" row type  */
    render: DataGridRenderCell<T, K>;
    /** render the edit cell */
    renderEdit: DataGridRenderCell<T, K>;
    /** render the caption cell */
    renderCaption: DataGridRenderCaption;
    /** render the cell, only called in "group" row type  */
    renderGroup: DataGridRenderCell<T, K>;
    /** compare function for sorting */
    sortCompare: DataGridSortCompare<T, K>;
    /**  */
    //quickFilter: DataGridQuickFilter<T, K>; //TODO
}>;

/** typed definition for the column builder column options, containing column (primitive) props and callbacks */
export type DataGridColumnOptions<T extends DataGridRowType, K extends DataGridRowKey<T>> = DataGridColumnProps &
    DataGridColumnCallbacks<T, K>;

/** We redefine getValue signature for a date-time column so it always receives DataGridFormattedDate. */
export type DataGridDateTimeColumnOptions<T extends DataGridRowType, K extends DataGridRowKey<T>> = TypedOmit<
    DataGridColumnOptions<T, K>,
    'getValue' | 'renderGroup'
> & {
    renderGroup?: DataGridDateTimeRenderGroup<T, K>;
};
/** typed definition for the action column options, the render params carry the rowId but no value */
export type DataGridActionColumnOptions<T extends DataGridRowType> = TypedOmit<
    DataGridColumnProps,
    'editable' | 'groupable' | 'sortable' | 'sorting' | 'sortingOrder' | 'aggregable' | 'filterable' | 'exportable'
> & {
    render: DataGridActionRenderCell<T>;
    renderCaption?: DataGridRenderCaption;
};
/** typed definition for the get detail parameters */
export type DataGridGetDetailParams<T extends DataGridRowType> = {
    rowId: string;
    row: T;
};
export type DataGridGetDetail<T extends DataGridRowType> = ({ rowId, row }: DataGridGetDetailParams<T>) => React.ReactNode;

/** typed definition for the column builder object column options, enforcing getValue to return a primitive type. */
export type DataGridObjectColumnOptions<T extends DataGridRowType, K extends DataGridRowKey<T>> = TypedOmit<
    DataGridColumnOptions<T, K>,
    'getValue'
> & {
    getValue: (params: DataGridColumnCallbackParams<T, K>) => DataGridPrimitiveValue;
};

/**
 * Checks if T has a string index signature.  If `string` is assignable to
 * `keyof T`, it means T is basically Record<string, X> (important for dynamic tables)
 */
type HasStringIndexSignature<T> = string extends keyof T ? true : false;

/** helper type for correct key inference of primitive types */
type PrimitiveFieldKeys<T extends DataGridRowType> = HasStringIndexSignature<T> extends true
    ? string
    : {
          [K in DataGridRowKey<T>]: T[K] extends DataGridPrimitiveValue ? K : never;
      }[DataGridRowKey<T>];

/** helper type for correct key inference of object types */
type ObjectFieldKeys<T extends DataGridRowType> = HasStringIndexSignature<T> extends true
    ? string
    : {
          [K in DataGridRowKey<T>]: T[K] extends DataGridPrimitiveValue ? never : T[K] extends DataGridFormattedDate ? never : K;
      }[DataGridRowKey<T>];

/** helper type for correct key inference of date-time types */
type DateTimeFieldKeys<T extends DataGridRowType> = HasStringIndexSignature<T> extends true
    ? string
    : {
          [K in DataGridRowKey<T>]: T[K] extends DataGridFormattedDate ? K : never;
      }[DataGridRowKey<T>];

/** typed definition for the column builder. For BaseDataGrid, the usage of the builder is mandatory. */
export type DataGridColumnBuilder<T extends DataGridRowType> = {
    /**
     * Adds a new column definition to the data grid columns array, with the cell content
     * rendered from a primitive value. The `render` option is optional, and if provided it
     * will be used to render the cell content. The `renderGroup` option is optional, and if
     * provided it will be used to render the group cell content.
     *
     * @param field - The field name for the column, indicating which data field the column
     *                represents.
     * @param options - Configuration options for the column, including the rendering
     *                  behaviors.
     * @returns The DataGridColumnBuilder instance, allowing for method chaining.
     */
    addColumn: <K extends PrimitiveFieldKeys<T>>(field: K, options?: DataGridColumnOptions<T, K>) => DataGridColumnBuilder<T>;
    /**
     * Adds a new column definition to the data grid columns array, with the cell content
     * rendered from an object value. The `getValue` option is mandatory, and is used to
     * extract the value from the object. The `getGroupValue` option is optional, and if
     * provided it will be used to extract the value for grouping. The `render` option is
     * optional, and if provided it will be used to render the cell content. The
     * `renderGroup` option is optional, and if provided it will be used to render the group
     * cell content.
     *
     * @param field - The field name for the column, indicating which data field the column
     *                represents.
     * @param options - Configuration options for the column, including the value extraction
     *                  and rendering behaviors.
     * @returns The DataGridColumnBuilder instance, allowing for method chaining.
     */
    addObjectColumn: <K extends ObjectFieldKeys<T>>(
        field: K,
        options: DataGridObjectColumnOptions<T, K>
    ) => DataGridColumnBuilder<T>;
    /**
     * Add a column of type date-time. Automatically creates a formatter and a value getter.
     * The grouping value will be the formatted value. In grouping mode, the renderGroup will
     * get the formatted value. In leaf-mode, the render will get the raw value.
     * @param field The field name in the data object.
     * @param options Optional column options.
     * @returns The column builder instance.
     */
    addDateTimeColumn: <K extends DateTimeFieldKeys<T>>(
        field: K,
        options?: DataGridDateTimeColumnOptions<T, K>
    ) => DataGridColumnBuilder<T>;
    /**
     * Adds a new column definition to the data grid columns array, with the cell content
     * rendered from a custom action. The `render` option is mandatory, and is used to
     * render the cell content. The column is also configured to be non-hideable, non-editable,
     * non-groupable, non-sortable, non-aggregable, non-filterable, and non-exportable.
     * An action column is automatically pinned to the right and cannot be unpinned.
     *
     * @param field - The field name for the column, indicating which data field the column
     *                represents.
     * @param options - Configuration options for the column, including the rendering
     *                  behavior.
     * @returns The DataGridColumnBuilder instance, allowing for method chaining.
     */
    addActionColumn: (field: string, options: DataGridActionColumnOptions<T>) => DataGridColumnBuilder<T>;
};
