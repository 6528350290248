import { forwardRef, PropsWithChildren } from 'react';

type PaneProps = PropsWithChildren<{
    size?: number;
    percentage?: boolean;
    primary?: boolean;
    vertical?: boolean;
    hidden?: boolean;
}>;

const Pane = forwardRef<HTMLDivElement, PaneProps>(function Pane(props, ref) {
    const size = props.size || 0;
    const unit = props.percentage ? '%' : 'px';
    let classes = 'libyc-splitter-pane';
    const style: React.CSSProperties = {};
    if (!props.primary) {
        if (props.vertical) {
            style.height = `${size}${unit}`;
        } else {
            style.width = `${size}${unit}`;
        }
    } else {
        classes += ' libyc-splitter-pane--primary';
    }
    if (props.hidden) {
        style.display = 'none';
    }
    return (
        <div
            className={classes}
            ref={ref}
            style={style}
        >
            {props.children}
        </div>
    );
});

export default Pane;
