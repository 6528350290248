import { AuthenticationUtils } from '@/helpers/authenticationUtils';
import { ExtApplicationType } from '@/store/data/release/releasesSlice';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, Fab, Menu, MenuItem } from '@mui/material';
import { useCallback, useState } from 'react';
import { useReduxDispatch } from '../../store/configureStore';
import { deploymentUIActions } from '../../store/ui/deploymentsUISlice';
import { ProgressAdminDialog } from './ProgressAdminDialog';

export const EditVersionMenu = () => {
    const hasPermission = AuthenticationUtils.hasAppManagementPermission();
    const hasManageProgressAdminsPermissions = AuthenticationUtils.hasManageProgressAdminsPermissions();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const dispatch = useReduxDispatch();

    const handleFabAddClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (!hasPermission) {
                return;
            }
            setAnchorEl(event.currentTarget);
        },
        [hasPermission]
    );

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const updateVersion = useCallback(
        (item: ExtApplicationType, appIdentifier: string) => () => {
            setAnchorEl(null);
            dispatch(deploymentUIActions.setAppToUpdate({ type: item, appIdentifier: appIdentifier, appType: 'global' }));
        },
        [dispatch]
    );

    const [currentDialog, setCurrentDialog] = useState<'ProgressAdmin'>();

    const toggleDialog = useCallback(
        (dialog: typeof currentDialog) => () => {
            setAnchorEl(null);
            setCurrentDialog(dialog);
        },
        []
    );

    return (
        <>
            <Fab
                onClick={handleFabAddClick}
                size="small"
                color="primary"
                sx={{ position: 'absolute', right: '20px', bottom: '80px' }}
                disabled={!hasPermission}
            >
                <EditIcon fontSize="small" />
            </Fab>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* TODO: use typegen to generate version codes */}
                {hasManageProgressAdminsPermissions && (
                    <MenuItem onClick={toggleDialog('ProgressAdmin')}>Edit Progress Admins</MenuItem>
                )}
                {hasManageProgressAdminsPermissions && <Divider />}

                <MenuItem onClick={updateVersion('AvicadYC', 'AvicadYC_Dev_VersionKey')}>avicadYC-dev</MenuItem>
                <MenuItem onClick={updateVersion('AvicadYC', 'AvicadYC_Prod_VersionKey')}>avicadYC-prod</MenuItem>
                <Divider />
                <MenuItem onClick={updateVersion('AdminCenterYC', 'AdminCenterYC_Dev_VersionKey')}>adminCenterYC-dev</MenuItem>
                <MenuItem onClick={updateVersion('AdminCenterYC', 'AdminCenterYC_Prod_VersionKey')}>
                    adminCenterYC-cluster01
                </MenuItem>
                <Divider />
                <MenuItem onClick={updateVersion('ApplicationProxy', 'ApplicationProxy_VersionKey')}>application-proxy</MenuItem>
                <Divider />
                <MenuItem onClick={updateVersion('Vision', 'Vision_VersionKey')}>vision</MenuItem>
                <Divider />
                <MenuItem onClick={updateVersion('CustomerPortal', 'CustomerPortal_Test_VersionKey')}>
                    customerportal-test
                </MenuItem>
                <MenuItem onClick={updateVersion('CustomerPortal', 'CustomerPortal_Prod_VersionKey')}>
                    customerportal-prod
                </MenuItem>
                <Divider />
                <MenuItem onClick={updateVersion('MebosYCWebApp', 'MebosYCWebApp_VersionKey')}>mebosyc-web-app</MenuItem>
                <Divider />
                <MenuItem onClick={updateVersion('ProgressInsights', 'ProgressInsights_VersionKey')}>progress-insights</MenuItem>
            </Menu>

            {hasManageProgressAdminsPermissions && currentDialog === 'ProgressAdmin' && (
                <ProgressAdminDialog onClose={toggleDialog(undefined)} />
            )}
        </>
    );
};
