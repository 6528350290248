import axios, { AxiosError } from 'axios';
import i18next from 'i18next';
import { ProgressAPIError } from '../definitions/autogenerated/types';
import { Log } from '../utils/debug';

declare global {
    interface Promise<T> {
        /**
         * custom catch block, which parses the caught error into a ProgressError object
         * @param errorHandler
         */
        catchError<TResult = never>(errorHandler: (error: ProgressError) => TResult | Promise<TResult>): Promise<T | TResult>;
    }
}

// eslint-disable-next-line no-extend-native
Promise.prototype.catchError = function <T>(
    this: Promise<T>,
    errorHandler: (error: ProgressError) => T | Promise<T>
): Promise<T> {
    return this.catch((e) => {
        const progressError = parseProgressError(e);
        return errorHandler(progressError);
    });
};

/**
 * Used to indicate the fault origin of the error
 *
 * API      =>  Server responded with a valid ProgressError object
 *
 * Axios    =>  Internal Axios error, Server was not reachable
 *
 * Runtime  =>  error was thrown client side
 *
 * Unknown  =>  Any other JS runtime error
 */
type ErrorSource = 'API' | 'Axios' | 'Runtime' | 'Unknown';

// If not yet parsed, actual progress error sent from server will always be contained in response.data
type ServerResponseWithProgressError = null | {
    response?: {
        data?: Partial<ProgressError>;
    };
};

type RuntimeError = null | Partial<Error>;

type UserMessageError = null | {
    response?: {
        data?: {
            UserMessages?: {
                Value: string;
                Language: string;
            }[];
        };
    };
};

// Error when communicating with admincenter
type ApiError = null | {
    response?: {
        data?: {
            title: string;
        };
    };
};

export type ProgressError = ProgressAPIError & {
    /**
     * Indicates whether the object is already a parsed ProgressError
     */
    _$type: 'ProgressError';
    Source: ErrorSource;
    NativeError: unknown;

    /**
     * Returns true if the error was caused by aborting an axios request or a redux thunk.
     */
    IsAbortError: () => boolean;
};

/**
 * tries to parse the occured error of type any into a ProgressError
 * @param e - error object (important: Always pass the whole error object, otherwise it will not be parsed correctly)
 * @returns - ProgressError object
 */
export const parseProgressError = (e: unknown): ProgressError => {
    const progressErrorResult: ProgressError = {
        _$type: 'ProgressError',
        Description: '',
        StatusCode: 0,
        Source: 'Unknown',
        Stacktrace: null,
        ValidationErrors: null,
        NativeError: e,
        IsAbortError: () => axios.isCancel(e) || (e as Error | undefined)?.name === 'AbortError',
    };

    if (!e || typeof e !== 'object') {
        //invalid error parameter
        progressErrorResult.Description = '' + e;
        Log.error('Unable to parse error:', progressErrorResult.Description);
        return progressErrorResult;
    }

    // skip overrides if already parsed
    const progressError = e as ProgressError;
    if (progressError._$type === 'ProgressError') {
        return progressError;
    }

    applyRuntimeErrorOverrides(progressErrorResult, e);

    applyAxiosErrorOverrides(progressErrorResult, e);

    applyUserMessageErrorOverrides(progressErrorResult, e);

    applyAPIErrorOverrides(progressErrorResult, e);

    applyProgressErrorOverrides(progressErrorResult, e);

    fallbackError(progressErrorResult, e);

    return progressErrorResult;
};

const applyRuntimeErrorOverrides = (currentProgressError: ProgressError, e: unknown) => {
    // if an error object is thrown in the client
    const errorInClient = e as RuntimeError;
    if (errorInClient?.name) {
        currentProgressError.Description = errorInClient.message
            ? `${errorInClient.name}: ${errorInClient.message}`
            : errorInClient.name;
        currentProgressError.Source = 'Runtime';
    }
};

const applyAxiosErrorOverrides = (currentProgressError: ProgressError, e: unknown) => {
    if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        currentProgressError.StatusCode = axiosError.response?.status ?? 500;
        currentProgressError.Description = `${axiosError.code ? axiosError.code + ' - ' : ''}${axiosError.message}`;
        currentProgressError.Source = 'Axios';
    }
};

const applyUserMessageErrorOverrides = (currentProgressError: ProgressError, e: unknown) => {
    const userMessageError = e as UserMessageError;
    if (userMessageError?.response?.data?.UserMessages) {
        const userMessages = userMessageError.response.data.UserMessages;

        // for mebosYC specific usermessages
        if (userMessages.length > 0) {
            Log.debug('[Debug] error has response.data has usermessage');
            currentProgressError.Description = userMessages[0].Value; // Fallback language
            for (const message of userMessages) {
                if (message.Language.substring(2).toLowerCase() === i18next.language.toLowerCase()) {
                    currentProgressError.Description = message.Value;
                    Log.debug('[Debug] error has response.data found usermessage with matching lang');
                }
            }

            // parse error:
            const regex = /(?<message>.*?)[\n|\r|\r\n]/gim;

            const match = regex.exec(currentProgressError.Description);
            if (match !== null && match.groups !== undefined) {
                const message = match.groups.message;
                currentProgressError.Description = message;
            }

            currentProgressError.Source = 'API';
        }
    }
};

// Error when communicating with admincenter
const applyAPIErrorOverrides = (currentProgressError: ProgressError, e: unknown) => {
    const apiError = e as ApiError;
    if (apiError?.response?.data?.title) {
        currentProgressError.Description = apiError.response.data.title;
        currentProgressError.Source = 'API';
    }
};

const applyProgressErrorOverrides = (currentProgressError: ProgressError, e: unknown) => {
    const error = e as ServerResponseWithProgressError;
    // If not yet parsed, actual progress error sent from server will always be contained in response.data
    const progressError = error?.response?.data;
    if (progressError) {
        if (progressError.Description && progressError.StatusCode) {
            currentProgressError.Description = progressError.Description;
            currentProgressError.StatusCode = progressError.StatusCode;
            currentProgressError.Source = 'API';
        }
        if (progressError.Stacktrace) {
            currentProgressError.Stacktrace = progressError.Stacktrace;
        }
        if (progressError.ValidationErrors) {
            currentProgressError.ValidationErrors = progressError.ValidationErrors;
        }
    }
};

// sets description in case none of the overrides have been applied / the error is not derived from an error class
const fallbackError = (currentProgressError: ProgressError, e: unknown) => {
    if (currentProgressError.Source === 'Unknown' && !currentProgressError.Description) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorObj = e as any;
        if ('message' in errorObj) {
            currentProgressError.Description = errorObj.message;
        } else if ('Message' in errorObj) {
            currentProgressError.Description = errorObj.Message;
        } else if ('description' in errorObj) {
            currentProgressError.Description = errorObj.description;
        } else if ('Description' in errorObj) {
            currentProgressError.Description = errorObj.Description;
        }
        //fallback - return .toString() content
        else if (errorObj.toString) {
            currentProgressError.Description = errorObj.toString();
        }
    }
};
