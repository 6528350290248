import styled from '@emotion/styled';
import { Grid as MUIGrid, Paper, SxProps, Typography } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { BaseDataGridProps, DataGridColumnProps, DataGridRowType } from '@progress/base-ui';
import { PropsWithChildren } from 'react';

//#region default grid and column props
export const minWidthColumnProps: DataGridColumnProps = {
    flexWidth: 1,
    minWidth: 200,
};

export const limitedColumnProps: DataGridColumnProps = {
    showMenu: false,
    exportable: false,
    groupable: false,
    hideable: false,
};

export const defaultGridProps = <T extends DataGridRowType>(): Partial<BaseDataGridProps<T>> => ({
    className: 'progress-table',
    disableColumnFilter: true,
    disableColumnPinning: true,
    disableColumnReorder: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    disableAggregation: true,
    //columnHeaderHeight: 42,
    //rowHeight: 32,
    slotProps: {
        loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'linear-progress',
        },
    },
});

//#endregion

export const CustomGridToolbar = () => (
    <div className="customToolbar">
        <GridToolbarQuickFilter
            autoComplete="off"
            variant="outlined"
            size="small"
            style={{ padding: '10px 0' }}
        />
    </div>
);

//#region Mobile grid components

export type MobileGridProps = PropsWithChildren<{
    gridKey: string | number;
    spacing?: number;
    sx?: SxProps;
}>;

export const MobileGrid = ({ gridKey, spacing, sx, children }: MobileGridProps) => (
    <Paper
        key={gridKey}
        className="mobile-row"
    >
        <MUIGrid
            container
            spacing={spacing ?? 2}
            sx={sx}
        >
            {children}
        </MUIGrid>
    </Paper>
);

export const MobileItemRowTypography = styled(Typography)({ overflow: 'hidden', textOverflow: 'ellipsis' });

export type MobileItemRowProps = PropsWithChildren<{
    title: string;
    isObject?: boolean;
}>;

export const MobileItemRow = ({ title, isObject, children }: MobileItemRowProps) => {
    if (!isObject) {
        children = <MobileItemRowTypography>{children}</MobileItemRowTypography>;
    }
    return (
        <>
            <MUIGrid
                item
                xs={6}
            >
                <Typography>{title}</Typography>
            </MUIGrid>
            <MUIGrid
                item
                xs={6}
            >
                {children}
            </MUIGrid>
        </>
    );
};
//#endregion
