import styled from '@emotion/styled';
import { Box } from '@mui/material';
import {
    // eslint-disable-next-line no-restricted-imports
    DataGridPremium,
    DataGridPremiumProps,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridColDef,
    GridGroupNode,
    GridRowId,
    GridRowSelectionPropagation,
    GridSlotsComponentsProps,
    GridSortDirection,
    GridValidRowModel,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getMuiDataGridTranslations, useTypedTranslation } from '../definitions/languages';
import { TypedOmit } from '../definitions/Partials';
import { useResize } from '../hooks/useResize';
import BaseDataGridStyles from './baseDataGridStyles.css';
import {
    DATAGRID_MOBILE_VIEW_FIELD,
    DataGridColumns,
    DataGridDensity,
    DataGridGetCellClassName,
    DataGridGetDetail,
    DataGridGetRowId,
    DataGridMobileRenderCell,
    DataGridRowId,
    DataGridRows,
    DataGridRowSelectionType,
    DataGridRowType,
    DataGridUIState,
} from './baseDataGridTypes';
import { getAllExpandedGroupRowIds } from './baseDataGridUtils';
import {
    OnRowReorderEndHandler,
    OnRowReorderingHandler,
    OnRowReorderStartHandler,
    useBaseDataGridRowReordering,
} from './useBaseDataGridRowReordering';
import { useBaseDataGridRowSelection } from './useBaseDataGridRowSelection';
import { useBaseDataGridUIState } from './useBaseDataGridUIState';

//export type BaseDataGridProps<T extends DataGridRowType> = DataGridPremiumProps<T>;
const StyledBaseDataGrid = styled(DataGridPremium)(BaseDataGridStyles);

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type BaseDataGridProps<T extends DataGridRowType = DataGridRowType> = TypedOmit<
    DataGridPremiumProps<T>,
    | 'rows'
    | 'columns' //we need to ensure that the builder is used for columns.
    | 'getDetailPanelContent'
    | 'getDetailPanelHeight'
    | 'getRowId'
    | 'sortModel'
    | 'onSortModelChange'
    | 'rowGroupingModel'
    | 'onRowSelectionModelChange'
    | 'onRowGroupingModelChange'
    | 'onColumnWidthChange'
    | 'onColumnOrderChange'
    | 'columnVisibilityModel'
    | 'onColumnVisibilityModelChange'
    | 'initialState'
    | 'localeText'
    | 'density'
    | 'getCellClassName'
    | 'checkboxSelection'
    | 'rowSelection'
    | 'disableRowSelectionOnClick'
    | 'disableMultipleRowSelection'
    | 'rowSelectionModel'
    | 'autoHeight' //deprecated
    | 'rowHeight'
    | 'columnHeaderHeight'
    | 'hideFooter' //we hide the footer by default, it must be enabled with "showFooter"
    | 'rowSelectionPropagation'
    | 'onRowOrderChange'
    | 'getRowHeight'
    | 'pinnedColumns'
> & {
    rows: DataGridRows<T>; // force the use of useDataGridRows
    columns: DataGridColumns<T>; // force the use of useDataGridColumns
    density?: DataGridDensity;
    /** Type of row selection, defaults to "single". Will enable checkBox selection when set to "multiple". Disables row selection when set to "none" */
    rowSelection?: DataGridRowSelectionType;
    /** Controlled state of the row selection. */
    rowSelectionState?: DataGridRowId[];
    /** Disables row selection when set to false. Please note that this is always false when rowSelection="none" */
    rowSelectionOnClick?: boolean;
    /** Controlled state of the grid - includes column visibility, order, width, sorting, grouping and pinning */
    gridState?: Partial<DataGridUIState<T>>;
    /** Show footer, defaults to false */
    showFooter?: boolean;
    /** Callback triggered when any of the grid state changes: column visibility, order, width, sorting, grouping or pinning */
    onGridStateChange?: (state: Partial<DataGridUIState<T>>) => void;
    onRowSelectionChange?: (rowIds: DataGridRowId[]) => void;
    getDetail?: DataGridGetDetail<T>;
    getCellClassName?: DataGridGetCellClassName<T>;
    getRowId: DataGridGetRowId<T>;
    mobileView?: {
        /** switch to compact render mode below this width, default: 600 */
        maxWidth: number;
        /** render the compact mode */
        render: DataGridMobileRenderCell<T>;
    };
    /** row reordering */
    /** return true / false when the rows are allowed to be dragged */
    onRowReorderStart?: OnRowReorderStartHandler;
    /** handler when dragging is finished. Use this to modify your data */
    onRowReorderEnd?: OnRowReorderEndHandler;
    /** return true / false when the rows are allowed to be dragged over the overRow */
    onRowReordering?: OnRowReorderingHandler;
};

export type MuiGridSortItem<T extends GridValidRowModel> = {
    field: keyof T;
    sort: GridSortDirection;
};

export type MuiGridSortModel<T extends GridValidRowModel> = MuiGridSortItem<T>[];

const gridRowSelectionPropagation: GridRowSelectionPropagation = { parents: true, descendants: true };

export function BaseDataGrid<T extends DataGridRowType>(props: BaseDataGridProps<T>) {
    const { language } = useTypedTranslation();

    const {
        rows,
        columns: externalColumns,
        apiRef: externalApiRef,
        getDetail,
        gridState,
        density = 'standard',
        rowSelection = 'single',
        rowSelectionOnClick = true,
        rowSelectionState,
        showFooter = false,
        onGridStateChange,
        onRowSelectionChange,
        getCellClassName,
        rowReordering = false,
        onRowReorderStart,
        onRowReorderEnd,
        onRowReordering,
        isGroupExpandedByDefault,
        mobileView,
        ...rest
    } = props;

    const internalGridApiRef = useGridApiRef();
    const gridApiRef = externalApiRef || internalGridApiRef;

    const localeText = useMemo(() => getMuiDataGridTranslations(language), [language]);

    const [gridContainerRef, { width: gridWidth }] = useResize(); //TODO: make a "useResponsive" hook
    const [mobileLayout, setMobileLayout] = useState(false); //mobileView && gridWidth > 0 && gridWidth > mobileView.maxWidth);

    useEffect(() => {
        if (!mobileView || gridWidth <= 0) return;
        if (mobileLayout && gridWidth > mobileView.maxWidth) {
            setMobileLayout(false);
        } else if (!mobileLayout && gridWidth <= mobileView.maxWidth) {
            setMobileLayout(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- exclude mobileLayout since the effect sets it
    }, [gridContainerRef, gridWidth, mobileView]);

    const getRowHeight = useCallback(() => {
        if (mobileLayout) return 'auto';
        return density === 'compact' ? 20 : 32;
    }, [density, mobileLayout]);

    // true once the grid got the rows
    const [rowsSet, setRowsSet] = useState(false);

    // for unknown reasons, the mui datagrid resets the row expansion state after the rows are "updated" (new reference)
    // therefore, we have to track the expanded row before a "possible update" and reset the state after the rows have been updated
    // see: https://github.com/mui/mui-x/issues/13064
    useEffect(() => {
        const expandedGroupRowIds: GridRowId[] = getAllExpandedGroupRowIds(gridApiRef);
        gridApiRef.current.setRows(rows.rows);
        expandedGroupRowIds.forEach((rowId) => {
            const node = gridApiRef.current.getRowNode(rowId);
            if (!node || node.type !== 'group') return;
            gridApiRef.current.setRowChildrenExpansion(rowId, true);
        });

        setRowsSet(rows.rows.length > 0);
    }, [rows, gridApiRef]);

    /** strongly typed getDetail - with rowId as string and row as T */
    const getDetailPanelContent = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
        ({ id, row }) => (getDetail ? getDetail({ rowId: id as string, row }) : undefined),
        [getDetail]
    );

    const { handleRowSelectionModelChange } = useBaseDataGridRowSelection(
        gridApiRef,
        rowSelection,
        rowSelectionState,
        rowSelectionOnClick,
        onRowSelectionChange
    );

    /* Grid state management: column order, visibility, widths, sorting, grouping, pinning */

    const {
        handleGridColumnOrderChanged,
        handleColumnVisibilityModelChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleRowGroupingModelChange,
        handlePinnedColumnsChange,
        getTogglableColumns,
        gridSorting,
        gridGrouping,
        gridPinning,
        columnVisibility,
    } = useBaseDataGridUIState(gridState, gridApiRef, externalColumns, onGridStateChange);

    /* Row reordering */
    const isRowReorderingEnabled = useMemo(() => {
        if (props.disableRowGrouping !== true) return false; //disable rowSorting if row grouping is enabled
        if (externalColumns.columns.some((c) => c.sortable)) return false; //disable rowSorting if at least one column is sortable
        return rowReordering;
    }, [externalColumns.columns, props.disableRowGrouping, rowReordering]);

    const { handleStateChange, reorderColDef } = useBaseDataGridRowReordering<T>(
        isRowReorderingEnabled,
        props.loading,
        gridApiRef,
        onRowReorderStart,
        onRowReorderEnd,
        onRowReordering
    );

    const mobileColumns = useMemo<GridColDef[]>(() => {
        return [
            {
                field: DATAGRID_MOBILE_VIEW_FIELD,
                headerName: '',
                flex: 1,
                disableReorder: true,
                renderCell: ({ row }) => mobileView?.render({ row }),
            },
        ];
    }, [mobileView]);

    const columns = useMemo(() => {
        const result = externalColumns.columns
            .toSorted((a, b) => {
                return (gridState?.order?.[a.field as keyof T] ?? 0) - (gridState?.order?.[b.field as keyof T] ?? 0);
            })
            .map((col) => {
                const column = { ...col }; // avoid mutation the original column definition
                if (gridState?.width?.[column.field as keyof T] !== undefined) {
                    column.width = gridState.width[column.field as keyof T];
                    column.flex = undefined;
                }
                return column;
            });
        //Fix: manually add the detail panel toggle column, otherwise the columns "resort" when grouping and resizing afterwards
        getDetail && result.unshift({ ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, hideable: false, _columnType: 'detail' });
        if (isRowReorderingEnabled) {
            result.unshift(reorderColDef);
        }
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalColumns.columns, gridState?.order, gridState?.width, isRowReorderingEnabled]);

    const slotProps = useMemo<GridSlotsComponentsProps>(
        () => ({
            ...(props.slotProps ?? {}),
            columnsManagement: {
                ...(props.slotProps?.columnsManagement ?? {}),
                getTogglableColumns,
                disableResetButton: true,
            },
            loadingOverlay: { variant: 'linear-progress', noRowsVariant: 'linear-progress' },
        }),
        [getTogglableColumns, props.slotProps]
    );

    /**
     * this is necessary because within the "isGroupExpandedByDefault" the children in the node are not set (empty array),
     * but when getting them through the api the children are set (probably mui bug). TODO: remove when it is resolved.
     */
    const isGroupExpanded = useCallback(
        (node: GridGroupNode): boolean => {
            if (!isGroupExpandedByDefault) return false;
            const rowNode = gridApiRef?.current?.getRowNode(node.id);
            if (!rowNode || rowNode.type !== 'group') return false;
            return isGroupExpandedByDefault(rowNode);
        },
        [gridApiRef, isGroupExpandedByDefault]
    );

    return (
        <Box
            sx={{ height: '100%', width: '100%' }}
            ref={gridContainerRef}
        >
            <StyledBaseDataGrid
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- type assertion needed for styled since it doesn't pass the generics properly */
                {...(rest as any)}
                columns={mobileLayout ? mobileColumns : columns}
                apiRef={gridApiRef}
                density={density}
                columnHeaderHeight={density === 'compact' ? 34 : 32} //note the height is not exact with density set
                getRowHeight={getRowHeight}
                localeText={localeText}
                getDetailPanelContent={getDetail ? getDetailPanelContent : undefined}
                getDetailPanelHeight={() => 'auto'}
                columnVisibilityModel={columnVisibility}
                sortModel={gridSorting}
                pinnedColumns={gridPinning}
                rowGroupingModel={gridGrouping}
                disableDensitySelector
                checkboxSelection={rowSelection === 'multiple'}
                disableRowSelectionOnClick // disable the native selection on click, so we can use our own behavior
                disableMultipleRowSelection={rowSelection !== 'multiple'}
                rowSelection={rowSelection !== 'none'}
                rowSelectionModel={rowSelectionState}
                rowSelectionPropagation={rowSelection === 'multiple' && rowsSet ? gridRowSelectionPropagation : undefined} // wait a render before providing the propagation to fix https://github.com/mui/mui-x/issues/15619
                hideFooter={!showFooter}
                onSortModelChange={onGridStateChange ? handleSortModelChange : undefined}
                onRowGroupingModelChange={onGridStateChange ? handleRowGroupingModelChange : undefined}
                onColumnWidthChange={onGridStateChange ? handleColumnWidthChange : undefined}
                onColumnOrderChange={onGridStateChange ? handleGridColumnOrderChanged : undefined}
                onColumnVisibilityModelChange={handleColumnVisibilityModelChange} //we alter the columnvisibility model, it must always be controlled
                onPinnedColumnsChange={handlePinnedColumnsChange} //we alter the columnpinning model, it must always be controlled
                onRowSelectionModelChange={handleRowSelectionModelChange} // we always need to update our internal ref
                getCellClassName={getCellClassName ? getCellClassName : undefined}
                slotProps={slotProps}
                isGroupExpandedByDefault={isGroupExpandedByDefault ? isGroupExpanded : undefined}
                onStateChange={handleStateChange}
            />
        </Box>
    );
}
