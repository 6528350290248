import DataGrid from '@/components/DataGrid';
import { minWidthColumnProps } from '@/components/DataGridComponents';
import { Log } from '@/helpers/debug';
import useDataGridState from '@/hooks/useDataGridState';
import {
    createGithubRelease as createGitHubRelease,
    fetchGithubReleases as fetchGitHubReleases,
} from '@/store/data/github/actions';
import { OpenInNew } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Container, IconButton, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
    AutoRefreshButton,
    DataGridActionRenderCell,
    DataGridRenderCell,
    useDataGridColumns,
    useDataGridRows,
} from '@progress/base-ui';
import { useSnackbar } from 'notistack';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTypedTranslation } from '../definitions';
import { GithubRelease } from '../definitions/autogenerated/types';
import { useReduxDispatch, useTypedSelector } from '../store/configureStore';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '2100px !important',
}));

/* const RedText = styled(Container)({
    color: '#ff3d00',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
});

const GreenText = styled(Container)(() => ({
    color: '#00a152',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
})); */

const rootStyle: CSSProperties = {
    margin: '0',
    textAlign: 'center',
};

const divStyle: CSSProperties = {
    width: '100%',
};

const divStyleHead: CSSProperties = {
    display: 'inline-block',
    width: '100%',
    alignItems: 'relative',
    margin: '1',
};

const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
};

/* const renderReleaseState =
    (maxState: number): DataGridRenderCell<GithubRelease, 'ReleaseState'> =>
    ({ row }) => {
        return (
            <div>
                {row.ReleaseState < maxState ? (
                    <RedText>{row.ReleaseState + '/' + maxState}</RedText>
                ) : (
                    <GreenText>{row.ReleaseState + '/' + maxState}</GreenText>
                )}
            </div>
        );
    }; */

const renderReleaseCreator: DataGridRenderCell<GithubRelease, 'ReleaseCreator'> = ({ row }) => {
    return <div>{row.ReleaseCreator}</div>;
};

const renderReleaseTag: DataGridRenderCell<GithubRelease, 'ReleaseTag'> = ({ row }) => {
    return (
        <div>
            {row.ReleaseTag}
            <IconButton
                color="primary"
                size="small"
                onClick={() => {
                    copyText(row.ReleaseTag);
                }}
            >
                <ContentCopyIcon />
            </IconButton>
        </div>
    );
};

const renderReleaseTagLink: DataGridActionRenderCell<GithubRelease> = ({ row }) => {
    return (
        <div>
            <IconButton
                href={'https://github.com/ProgressGroupDev/Projekte/actions/runs/' + row.ReleaseId}
                target="_blank"
                size="medium"
                disabled={row.ReleaseId == null}
            >
                <OpenInNew />
            </IconButton>
        </div>
    );
};

export default function GitHubList() {
    const releases = useTypedSelector((state) => state.data.github.releases);
    const rows = useDataGridRows<GithubRelease>(() => releases, [releases]);

    //const maxState = useTypedSelector((state) => state.data.github.maxState);
    const [isLoading, setIsLoading] = useState(false);

    const apiRef = useGridApiRef();
    const { t } = useTypedTranslation();
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { gridState, setGridState } = useDataGridState({
        dataGridId: 'gitHubList',
        defaults: {
            sorting: {
                ReleaseTag: 'desc',
            },
        },
    });

    const columns = useDataGridColumns<GithubRelease>(
        (builder) => {
            builder
                .addColumn('ReleaseTag', {
                    ...minWidthColumnProps,
                    caption: t('main', 'releaseTag'),
                    render: renderReleaseTag,
                })
                //TODO this is no longer working and should be fixed by an intern!
                /* .addColumn('ReleaseState', {
                    ...minWidthColumnProps,
                    caption: t('github', 'releaseState'),
                    toolTip: t('github', 'releaseStateDescription'),
                    render: renderReleaseState(maxState),
                }) */
                .addColumn('ReleaseCreator', {
                    ...minWidthColumnProps,
                    caption: t('github', 'releaseCreator'),
                    render: renderReleaseCreator,
                })
                .addActionColumn('ReleaseTagLink', {
                    ...minWidthColumnProps,
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                    caption: t('github', 'pipelineLink'),
                    render: renderReleaseTagLink,
                });
        },
        [t]
    );

    const loadGitHubReleases = useCallback(() => {
        setIsLoading(true);

        dispatch(fetchGitHubReleases())
            .unwrap()
            .catchError((e) => {
                enqueueSnackbar(t('main', 'loadingError'), { variant: 'error' });
                Log.error(e.Description);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, enqueueSnackbar, t]);

    useEffect(() => {
        loadGitHubReleases();
    }, [loadGitHubReleases]);

    const newGitHubRelease = useCallback(() => {
        dispatch(createGitHubRelease())
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('github', 'createSuccess'), { variant: 'success' });
                loadGitHubReleases();
            })
            .catchError(() => {
                enqueueSnackbar(t('github', 'createError'), { variant: 'error' });
            });
    }, [dispatch, enqueueSnackbar, t, loadGitHubReleases]);

    return (
        <StyledContainer
            maxWidth="lg"
            className="container"
        >
            <div
                className="container"
                style={rootStyle}
            >
                <div
                    className="container"
                    style={divStyle}
                >
                    <div style={divStyleHead}>
                        <Box
                            m={1}
                            //margin
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <h1 className="containerTitle">{t('github', 'title')}</h1>
                            <Box>
                                <AutoRefreshButton
                                    variant="outlined"
                                    loading={isLoading}
                                    onRefresh={loadGitHubReleases}
                                    onClick={loadGitHubReleases}
                                    refreshInterval={30000}
                                >
                                    {t('main', 'refresh')}
                                </AutoRefreshButton>

                                <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    style={{ marginLeft: 10, height: 36.5 }}
                                    onClick={newGitHubRelease}
                                >
                                    {t('releaseList', 'createRelease')}
                                </Button>
                            </Box>
                        </Box>
                    </div>

                    <Paper className="container">
                        <DataGrid
                            dataGridId="gitHubList"
                            rows={rows}
                            columns={columns}
                            apiRef={apiRef}
                            gridState={gridState}
                            onGridStateChange={setGridState}
                            getRowId={getRowId}
                            loading={isLoading}
                            rowSelection="none"
                        />
                    </Paper>
                </div>
            </div>
        </StyledContainer>
    );
}

const getRowId = (release: GithubRelease) => `${release.ReleaseTag}`;
