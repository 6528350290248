import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ApplicationType, getAppLogo } from '../../utils/LogoHelper';

type ProgressAppLogoProps = {
    appType: ApplicationType;
    route?: string;
};

export const ProgressAppLogo = (props: ProgressAppLogoProps) => {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            sx={{ mx: 2 }}
        >
            {props.route ? (
                <NavLink
                    to={props.route}
                    style={{ textDecoration: 'none', color: 'unset', lineHeight: 1, fontSize: '28px' }}
                >
                    {getAppLogo(props.appType)}
                </NavLink>
            ) : (
                <Box sx={{ lineHeight: 1, fontSize: '28px' }}>{getAppLogo(props.appType)}</Box>
            )}
        </Box>
    );
};
