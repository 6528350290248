import styled from '@emotion/styled';
import PrevPageIcon from '@mui/icons-material/KeyboardArrowLeft';
import NextPageIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { usePagination, UsePaginationProps } from '@mui/lab';
import { PDFDocumentInfo } from '../hooks/usePdfConverter';
import { pdfViewerClass } from './pdfViewerStyles';
import { PDFViewerHandle } from './pdfViewerTypes';

export type PDFViewerPaginationProps = {
    documentInfo: PDFDocumentInfo | undefined;
    currentPage?: number;
    pdfViewer: () => PDFViewerHandle;
    onSwitchPage: UsePaginationProps['onChange'];
};

const PDFViewerPagination = ({ documentInfo, currentPage, onSwitchPage: onChange }: PDFViewerPaginationProps) => {
    const { items } = usePagination({
        count: documentInfo?.pageCount ?? 0,
        page: currentPage,
        onChange,
    });
    if (!documentInfo) return null;
    return (
        <PDFViewerPaginationContainer {...pdfViewerClass('pagination')}>
            <ol {...pdfViewerClass('pagination-list')}>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children: React.ReactNode = null;
                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = (
                            <button {...pdfViewerClass('pagination-page', 'more')}>
                                <MoreHorizIcon />
                            </button>
                        );
                    } else if (type === 'page') {
                        children = (
                            <button
                                {...pdfViewerClass('pagination-page', selected ? 'selected' : undefined)}
                                type="button"
                                {...item}
                            >
                                {page}
                            </button>
                        );
                    } else {
                        // Define a function to determine which icon to render
                        const renderIcon = (type: string) => {
                            switch (type) {
                                case 'next':
                                    return <NextPageIcon />; // Render the next page icon
                                case 'previous':
                                    return <PrevPageIcon />; // Render the previous page icon
                                default:
                                    return null; // Render nothing if the type is unrecognized
                            }
                        };
                        children = (
                            <button
                                {...pdfViewerClass('pagination-page', type)}
                                type="button"
                                {...item}
                            >
                                {renderIcon(type)}
                            </button>
                        );
                    }
                    const key = `pagination-item-${index}`;
                    return (
                        <li
                            {...pdfViewerClass('pagination-item', type)}
                            key={key}
                        >
                            {children}
                        </li>
                    );
                })}
            </ol>
        </PDFViewerPaginationContainer>
    );
};

/** styles */

const PDFViewerPaginationContainer = styled('div')({
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
    [`& .${pdfViewerClass('pagination-list').className}`]: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
    },
    [`& .${pdfViewerClass('pagination-item').className}`]: {
        '& > svg': {
            height: 36,
        },
    },
    [`& .${pdfViewerClass('pagination-page--selected').className}`]: {
        fontWeight: 'bold' /* to make the selected page bold */,
    },
    [`& .${pdfViewerClass('pagination-page--more').className}`]: {
        cursor: 'default',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#bababa', //and a darker background
    },
    [`& .${pdfViewerClass('pagination-page').className}`]: {
        width: 36,
        height: 36,
        fontSize: 14,
        padding: 4,
        margin: 1,
    },
});

export default PDFViewerPagination;
