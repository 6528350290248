import { css } from '@emotion/react';

const styles = css`
    // The expand icon is not centered vertically when density is "compact"
    [data-field='__detail_panel_toggle__'] {
        display: flex;
        /** hide expand/collapse icon when disabled */
        > button.Mui-disabled {
            display: none;
        }
    }

    // positioning of the grouping column cell: otherwise the grouping cell is cut off at the first/grouping column end
    .MuiDataGrid-groupingCriteriaCell {
        position: absolute;
    }

    &.MuiDataGrid-root {
        border-radius: 0;
    }

    // center the grouping expand/collapse icon when density is set to compact
    &.MuiDataGrid-root--densityCompact {
        /** font size for density "compact" */
        font-size: 12px;

        .MuiDataGrid-groupingCriteriaCellToggle > button {
            padding-top: 0;
            padding-bottom: 0;
        }

        /** center and resize the checkbox */
        .MuiCheckbox-root {
            width: 16px;
            min-width: 16px;
            max-width: 16px;
            height: 16px;
            position: relative;
            padding: 0;
            .MuiSvgIcon-root {
                width: 100%;
                height: 100%;
            }
        }
        .MuiDataGrid-columnHeader .MuiCheckbox-root {
            top: 0.5px;
        }
    }

    // center the column header
    .MuiDataGrid-columnHeaderTitleContainerContent {
        line-height: var(--DataGrid-headerHeight);
    }

    .MuiDataGrid-scrollbar {
        z-index: 1;
    }

    /** disable cell focus border, enable row focus border */
    &.MuiDataGrid-root {
        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            &:focus,
            &:focus-within {
                outline: none;
            }
        }
        .MuiDataGrid-row {
            &:focus,
            &:focus-within {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: calc(var(--DataGrid-scrollbarSize) * var(--DataGrid-hasScrollY));
                    border: 1px solid #008acb;
                    pointer-events: none;
                    z-index: 10;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background: #008acb;
                    z-index: 10;
                }
            }
        }
    }
    /** headers and pinned columns styling */
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaders > div,
    .MuiDataGrid-scrollbarFiller,
    .MuiDataGrid-pinnedColumnHeaders {
        background-color: #f2f2f2 !important;
    }
    .MuiDataGrid-pinnedColumnHeaders {
        box-shadow: none;
    }

    .MuiDataGrid-filler--pinnedLeft,
    .MuiDataGrid-cell--pinnedLeft.MuiDataGrid-cell--withRightBorder {
        box-shadow: rgba(0, 0, 0, 0.21) 2px 0 4px -2px;
    }

    .MuiDataGrid-filler--pinnedRight,
    .MuiDataGrid-cell--pinnedRight.MuiDataGrid-cell--withLeftBorder {
        box-shadow: rgba(0, 0, 0, 0.21) -2px 0 4px -2px;
    }

    .MuiLinearProgress-root {
        z-index: 1000;
    }

    // add a border for an expanded master detail
    .MuiDataGrid-row--lastVisible,
    .MuiDataGrid-row--detailPanelExpanded,
    .MuiDataGrid-row--detailPanelExpanded.MuiDataGrid-row,
    .MuiDataGrid-row--detailPanelExpanded.MuiDataGrid-row--lastVisible {
        border-bottom: 1px solid #e0e0e0;
    }

    .MuiDataGrid-detailPanel {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .MuiDataGrid-row {
        transition: border-top 0.3s ease;
    }
    .MuiDataGrid-row.rowReordering--drag-over {
        border-top: 2px solid #098bca;
    }
    .MuiDataGrid-row.rowReordering--dragging {
        opacity: 0.6;
    }
    button.rowReordering--draggable {
        svg {
            transform: rotate(90deg);
            position: relative;
            top: -1px;
        }
    }
`;
export default styles;
