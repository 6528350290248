import { DragIndicator } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTypedTranslation } from '../definitions/languages';
import { useOnUnmount } from '../hooks/useOnUnmount';
import { Log } from '../utils/debug';
import { DataGridColDef, DataGridRowId, DataGridRowType } from './baseDataGridTypes';

/** return true / false when the rows are allowed to be dragged */
export type OnRowReorderStartHandler = (
    draggingRowId: DataGridRowId,
    selectedRowIds: DataGridRowId[],
    gridApi: GridApiPremium
) => boolean;
/** handler when dragging is finished */
export type OnRowReorderEndHandler = (draggingRowIds: DataGridRowId[], overRowId: DataGridRowId, gridApi: GridApiPremium) => void;
/** return true / false when the rows are allowed to be dragged over the overRow */
export type OnRowReorderingHandler = (
    draggingRowIds: DataGridRowId[],
    overRowId: DataGridRowId,
    gridApi: GridApiPremium
) => boolean;

//since we have pinned columns, we cannot use gridApiRef.getRowElement(dragOverRowId) - it will return only the pinned row, not the scrollable row
const addRowClass = (rowId: DataGridRowId, className: string) => {
    const rows = document.querySelectorAll('.MuiDataGrid-row[data-id="' + rowId + '"]');
    rows && rows.forEach((row) => row.classList.add(className));
};
const removeRowClass = (rowId: DataGridRowId, className: string) => {
    const rows = document.querySelectorAll('.MuiDataGrid-row[data-id="' + rowId + '"]');
    rows && rows.forEach((row) => row.classList.remove(className));
};

export const rowReorderingField = '__rowReordering';

/**  we decided to implement a custom row reordering, because the native one does not support dragging multiple rows and there is no mobile support */
export const useBaseDataGridRowReordering = <T extends DataGridRowType>(
    isRowReorderingEnabled: boolean,
    loading: boolean | undefined,
    gridApiRef: MutableRefObject<GridApiPremium>,
    onRowReorderStart: OnRowReorderStartHandler | undefined,
    onRowReorderEnd: OnRowReorderEndHandler | undefined,
    onRowReordering: OnRowReorderingHandler | undefined
) => {
    const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);

    const draggingRowIdsRef = useRef<DataGridRowId[]>([]);
    const dragOverRowIdRef = useRef<DataGridRowId | undefined>();

    const setDraggingRowIds = useCallback((ids: DataGridRowId[]) => {
        draggingRowIdsRef.current.forEach((draggingRowId) => removeRowClass(draggingRowId, 'rowReordering--dragging'));
        ids.forEach((draggingRowId) => addRowClass(draggingRowId, 'rowReordering--dragging'));

        draggingRowIdsRef.current = ids;
    }, []);

    const setDragOverRowId = useCallback((id: DataGridRowId | undefined) => {
        dragOverRowIdRef.current && removeRowClass(dragOverRowIdRef.current, 'rowReordering--drag-over');
        id && addRowClass(id, 'rowReordering--drag-over');

        dragOverRowIdRef.current = id;
    }, []);

    const { t } = useTypedTranslation();

    useOnUnmount(() => {
        setDraggingRowIds([]);
        setDragOverRowId(undefined);
    });

    const handleRowDragStart = useCallback(
        (event: DragEvent | TouchEvent) => {
            let target = event.target as HTMLElement;
            if ('touches' in event) {
                //is a touch start
                target = event.touches[0].target as HTMLElement;
                if (!target.closest('.rowReordering--draggable')) return; // check if the rowReordering column is used
                event.preventDefault();
            } //mouse drag start
            else event.dataTransfer && (event.dataTransfer.dropEffect = 'copy');
            if (!target.closest('.rowReordering--draggable')) return; // check if the rowReordering column is used
            target.style.cursor = 'move';
            //Log.debug('handleRowDragStart target', target.classList);
            if (target.classList.contains('MuiDataGrid-columnHeaderDraggableContainer')) {
                return; //ignore column headers
            }
            const draggingRowId = target.closest('[data-rowindex]')?.getAttribute('data-id') ?? undefined;
            //Log.debug('handleRowDragStart set draggingRowId', draggingRowId);
            if (!draggingRowId || event.ctrlKey || event.shiftKey) {
                //do not initiate drag when a selection is made (shift or ctrl)
                Log.error('handleRowDragStart draggingRowId not set');
                return event.preventDefault();
            }
            let selectedIds = gridApiRef.current.state.rowSelection as DataGridRowId[];
            if (!selectedIds.includes(draggingRowId)) {
                //when attempting to drag a row which is not selected, drag only that row
                gridApiRef.current.setRowSelectionModel([draggingRowId]);
                selectedIds = [draggingRowId];
            }
            if (onRowReorderStart && onRowReorderStart(draggingRowId, [...selectedIds], gridApiRef.current) === false) {
                Log.error('handleRowDragStart onSortRowsStart returned false');
                return event.preventDefault(); //drag start prevented here by callback
            }
            setDraggingRowIds([...selectedIds]);
            event.stopPropagation();
        },
        [gridApiRef, onRowReorderStart, setDraggingRowIds]
    );

    const handleRowDragEnd = useCallback(() => {
        if (
            !dragOverRowIdRef.current ||
            draggingRowIdsRef.current.length === 0 ||
            draggingRowIdsRef.current.find((rowId) => rowId === dragOverRowIdRef.current)
        ) {
            setDragOverRowId(undefined);
            if (draggingRowIdsRef.current.length) {
                setDraggingRowIds([]);
            }
            return; //cannot drag over the same row
        } else {
            onRowReorderEnd && onRowReorderEnd(draggingRowIdsRef.current, dragOverRowIdRef.current, gridApiRef.current);
            setDragOverRowId(undefined);
            if (draggingRowIdsRef.current.length) {
                setDraggingRowIds([]);
            }
        }
    }, [gridApiRef, onRowReorderEnd, setDragOverRowId, setDraggingRowIds]);

    const handleRowDragOver = useCallback(
        (event: DragEvent | TouchEvent) => {
            if (!draggingRowIdsRef.current.length) return;
            let target = event.target as HTMLElement;
            if ('touches' in event) {
                const { clientX, clientY } = event.touches[0];
                target = document.elementFromPoint(clientX, clientY) as HTMLElement;
            }
            const dragOverRowId = target.closest('[data-rowindex]')?.getAttribute('data-id') ?? undefined;
            if (!dragOverRowId) return;
            if (draggingRowIdsRef.current.find((rowId) => rowId === dragOverRowId)) {
                setDragOverRowId(undefined);
                Log.error('cannot drag over already dragging rows');
                return undefined;
            }
            if (onRowReordering && onRowReordering(draggingRowIdsRef.current, dragOverRowId, gridApiRef.current) === false)
                return;
            setDragOverRowId(dragOverRowId);
            event.preventDefault(); //if not prevented, it will show a "forbidden" cursor
        },
        [gridApiRef, onRowReordering, setDragOverRowId]
    );

    useEffect(() => {
        if (rootElement && isRowReorderingEnabled && !loading) {
            //mouse
            rootElement.addEventListener('dragstart', handleRowDragStart);
            rootElement.addEventListener('dragend', handleRowDragEnd);
            rootElement.addEventListener('dragover', handleRowDragOver);
            //touch
            rootElement.addEventListener('touchstart', handleRowDragStart);
            rootElement.addEventListener('touchend', handleRowDragEnd);
            rootElement.addEventListener('touchmove', handleRowDragOver);
        }

        return () => {
            if (!rootElement) return;
            //mouse
            rootElement.removeEventListener('dragstart', handleRowDragStart);
            rootElement.removeEventListener('dragend', handleRowDragEnd);
            rootElement.removeEventListener('dragover', handleRowDragOver);
            //touch
            rootElement.removeEventListener('touchstart', handleRowDragStart);
            rootElement.removeEventListener('touchend', handleRowDragEnd);
            rootElement.removeEventListener('touchmove', handleRowDragOver);
        };
    }, [gridApiRef, loading, handleRowDragEnd, handleRowDragOver, handleRowDragStart, isRowReorderingEnabled, rootElement]);

    const reorderColDef = useMemo<DataGridColDef<T>>(
        () => ({
            field: rowReorderingField,
            _columnType: 'reorder',
            headerName: '',
            minWidth: 30,
            maxWidth: 30,
            hideable: false,
            groupable: false,
            sortable: false,
            resizable: false,
            disableExport: true,
            aggregable: false,
            editable: false,
            filterable: false,
            pinnable: false,
            disableColumnMenu: true,
            display: 'flex',
            align: 'center',
            renderCell: ({ id }) => (
                <GridActionsCellItem
                    label={t('dataGrid', 'rowReordering')}
                    style={{ cursor: 'move' }}
                    icon={<DragIndicator />}
                    key={id}
                    className="rowReordering--draggable"
                    draggable="true"
                />
            ),
        }),
        [t]
    );

    const handleStateChange = useCallback(() => {
        setRootElement(gridApiRef.current?.rootElementRef?.current ?? null);
    }, [gridApiRef]);

    return {
        handleStateChange,
        reorderColDef,
    };
};
