import { Action, combineReducers, configureStore, isPlain } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk, { ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';
import { configSheetSlice, remoteFilesAPI } from './data/configSheet/configSheetSlice';
import { customersSlice } from './data/customers/customersSlice';
import { deploymentSlice } from './data/deployments/deploymentSlice';
import { githubSlice } from './data/github/githubSlice';
import { pipelinesSlice } from './data/pipelines/pipelinesSlice';
import { progressAppsSlice } from './data/progressApps/progressAppsSlice';
import { releaseSlice } from './data/release/releasesSlice';
import { dataGridUIReducer } from './ui/datagrid/dataGridUIReducer';
import { deploymentUISlice } from './ui/deploymentsUISlice';

export const rootReducer = combineReducers({
    data: combineReducers({
        deployments: deploymentSlice.reducer,
        progressApps: progressAppsSlice.reducer,
        configSheet: configSheetSlice.reducer,
        releases: releaseSlice.reducer,
        github: githubSlice.reducer,
        pipelines: pipelinesSlice.reducer,
        customers: customersSlice.reducer,
    }),
    ui: combineReducers({
        deployments: deploymentUISlice.reducer,
        dataGrid: dataGridUIReducer,
    }),
    [remoteFilesAPI.reducerPath]: remoteFilesAPI.reducer,
});

const isSerializable = (value: unknown) =>
    value instanceof Date || value instanceof FileSystemFileHandle ? true : isPlain(value);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: { isSerializable } }).concat(remoteFilesAPI.middleware, thunk),
});

export type RootState = ReturnType<typeof store.getState>;

export type ReduxDispatch = ReduxThunkDispatch<RootState, unknown, Action>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useReduxDispatch(): ReduxDispatch {
    return useDispatch<ReduxDispatch>();
}
