import { DataGridColumnsMap, DataGridId } from '@/definitions/dataGrid';
import { useReduxDispatch, useTypedSelector } from '@/store/configureStore';
import { selectGridState } from '@/store/ui/datagrid/dataGridUISelectors';
import { DataGridUIState } from '@progress/base-ui';
import { useCallback, useEffect } from 'react';
import { dataGridUIActions } from '../store/ui/datagrid/dataGridUIReducer';

export function getFromLocalStorage<Type>(key: string) {
    return new Promise<Type | undefined>((resolve, reject) => {
        let config: Type | undefined = undefined;

        const item = localStorage.getItem(key);
        if (item) {
            try {
                config = JSON.parse(item);
            } catch (error) {
                reject(error);
            }
        }
        resolve(config);
    });
}

export function saveToLocalStorage<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
}

type UseDataGridStateProps<K extends DataGridId, T extends DataGridColumnsMap[K]> = {
    dataGridId: K;
    defaults?: Partial<DataGridUIState<T>>;
};

const useDataGridState = <K extends DataGridId, T extends DataGridColumnsMap[K]>({
    dataGridId,
    defaults = {},
}: UseDataGridStateProps<K, T>) => {
    const dispatch = useReduxDispatch();

    const gridState = useTypedSelector(selectGridState(dataGridId));
    const gridStateKey = `gridState_${dataGridId}`;

    useEffect(() => {
        if (gridState === undefined) {
            //loaded grid state are undefined, set the defaults
            getFromLocalStorage(gridStateKey)
                .then((state) => {
                    if (!state) saveToLocalStorage(gridStateKey, defaults);
                    dispatch(dataGridUIActions.setGridState({ dataGridId, gridState: state ? state : defaults }));
                })
                .catchError(() => {
                    dispatch(dataGridUIActions.setGridState({ dataGridId, gridState: defaults }));
                });
        } else {
            saveToLocalStorage(gridStateKey, gridState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore changes for defaults
    }, [dataGridId, dispatch, gridState, gridStateKey]);

    const setGridState = useCallback(
        (gridState: DataGridUIState<T>) => {
            dispatch(
                dataGridUIActions.setGridState({
                    dataGridId,
                    gridState,
                })
            );
        },
        [dataGridId, dispatch]
    );

    const resetGridState = useCallback(() => {
        dispatch(
            dataGridUIActions.resetGridState({
                dataGridId,
                defaultGridState: defaults,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        gridState,
        setGridState,
        resetGridState,
    };
};

export default useDataGridState;
