import { useEffect } from 'react';

/**
 * Sets up a callback function to be called exactly once when the component is unmounted.
 *
 * @param {() => void} f - The callback function to be called on component unmount.
 * @return {void}
 */
export function useOnUnmount(func: () => void) {
    // the parameter function is called exactly once - on component unmount
    // eslint-disable-next-line no-restricted-syntax
    useEffect(() => {
        return () => {
            func();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
