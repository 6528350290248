import { fabric } from 'fabric';
import { createContext, FunctionComponent, ReactElement, useContext, useMemo, useState } from 'react';
import { initHistory } from './fabric-history';
import { initAligningGuidelines } from './guidelines';
import { ProgressCanvas } from './ProgressCanvas';

type FabricContextType = {
    canvas: ProgressCanvas | undefined;
    initCanvas(el: HTMLCanvasElement): ProgressCanvas;
};

const FabricContext = createContext<FabricContextType | undefined>(undefined);

export const useFabricContext = () => {
    const context = useContext(FabricContext);
    if (context === undefined) {
        throw new Error('useFabricContext must be used inside a FabricContextProvider');
    }
    return context;
};

export const FabricContextProvider = (props: { children: ReactElement | ReactElement[] | undefined | never[] }) => {
    const [canvasElement, setCanvasElement] = useState<ProgressCanvas>();

    const initCanvas = useMemo(
        () => (canvasEl: HTMLCanvasElement) => {
            const c = new ProgressCanvas(canvasEl, {
                height: 1260,
                width: 1800,
                backgroundColor: '#aabee6',
                stateful: true,
                fireRightClick: true,
                stopContextMenu: true,
                preserveObjectStacking: true,
                containerClass: 'fabric-container',
            });

            c.fromJSON({
                backgroundColor: '#aabee6',
                width: 1200,
                height: 840,
                controls: [],
            });

            initHistory(c, 100);
            initAligningGuidelines(c);

            c.on('object:scaling', (e) => {
                if (e.target instanceof fabric.ActiveSelection) {
                    for (const object of e.target.getObjects()) {
                        if (object.left !== undefined && e.target.scaleX !== undefined) object.left *= e.target.scaleX;
                        if (object.width !== undefined && e.target.scaleX !== undefined) object.width *= e.target.scaleX;
                        if (object.top !== undefined && e.target.scaleY !== undefined) object.top *= e.target.scaleY;
                        if (object.height !== undefined && e.target.scaleY !== undefined) object.height *= e.target.scaleY;
                    }

                    const w = (e.target.width ?? 0) * (e.target.scaleX ?? 0);
                    const h = (e.target.height ?? 0) * (e.target.scaleY ?? 0);
                    e.target.height = h;
                    e.target.width = w;
                    e.target.scaleX = 1;
                    e.target.scaleY = 1;
                }
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.canvas = c;

            setCanvasElement(c);
            return c;
        },
        []
    );

    const contextValue = useMemo(() => ({ canvas: canvasElement, initCanvas }), [canvasElement, initCanvas]);

    return <FabricContext.Provider value={contextValue}>{props.children}</FabricContext.Provider>;
};

interface WithCanvasProps {
    canvas: ProgressCanvas;
}

export const withCanvasContext =
    <P extends object>(Component: React.ComponentType<P & WithCanvasProps>): FunctionComponent<P> =>
    (props) => (
        <FabricContext.Consumer>
            {(p) => {
                if (p === undefined) throw new Error('withCanvasContext must be used within a FabricContext.Provider');
                if (!p.canvas) return <></>;
                return (
                    <Component
                        {...(props as P)}
                        canvas={p.canvas}
                    />
                );
            }}
        </FabricContext.Consumer>
    );
