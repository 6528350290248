import { DependencyList, useMemo } from 'react';
import { useTypedTranslation } from '../definitions/languages';
import { DataGridRows, DataGridRowType } from './baseDataGridTypes';

/**
 * useDataGridRows hook, required for use with BaseDataGrid. Ensures branded rows and rerender on language change.
 * @type T - DataGridRowType - mandatory generic type that extends DataGridRowType, e.g. MyTableRowType = MyApiRowType & DataGridRowType;
 * @param buildRows - A function that returns the unbranded rows (Record<string, unknown>[])
 * @param deps - Dependency list that your row-building logic depends on
 * @returns DataGridRowType[] that updates when language changes
 */
function useDataGridRows<T extends DataGridRowType = never>(buildRows: () => T[], deps: DependencyList): DataGridRows<T> {
    const { language } = useTypedTranslation();

    const rows = useMemo(
        () => (buildRows as () => T[])(),
        // eslint-disable-next-line react-hooks/exhaustive-deps -- deps is not exhaustive, language is wanted
        [...deps, language]
    );

    return useMemo<DataGridRows<T>>(() => ({ rows, _origin_: 'useDataGridRows' }), [rows]);
}

export default useDataGridRows;
