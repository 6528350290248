import { DataGridColumnsMap, DataGridId } from '@/definitions/dataGrid';
import { DataGridUIState } from '@progress/base-ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DataGridState = Partial<{
    [K in DataGridId]: DataGridUIState<DataGridColumnsMap[K]>;
}>;

const initialState: DataGridState = {};

type SetGridStatePayload<T extends DataGridId> = {
    dataGridId: T;
    gridState: DataGridUIState<DataGridColumnsMap[T]>;
};

type ResetGridStatePayload<T extends DataGridId> = {
    dataGridId: T;
    defaultGridState: DataGridUIState<DataGridColumnsMap[T]>;
};

export const dataGridUISlice = createSlice({
    name: 'dataGridUI',
    initialState: initialState,
    reducers: {
        setGridState: <T extends DataGridId>(state: DataGridState, action: PayloadAction<SetGridStatePayload<T>>) => {
            const { dataGridId, gridState } = action.payload;
            state = state || {};
            state[dataGridId] = state[dataGridId] || {};
            gridState.order === undefined || (state[dataGridId].order = gridState.order);
            gridState.visibility === undefined || (state[dataGridId].visibility = gridState.visibility);
            gridState.width === undefined || (state[dataGridId].width = gridState.width);
            gridState.sorting === undefined || (state[dataGridId].sorting = gridState.sorting);
            gridState.grouping === undefined || (state[dataGridId].grouping = gridState.grouping);
            gridState.pinning === undefined || (state[dataGridId].pinning = gridState.pinning);
        },
        resetGridState: <T extends DataGridId>(state: DataGridState, action: PayloadAction<ResetGridStatePayload<T>>) => {
            const { dataGridId, defaultGridState } = action.payload;
            state[dataGridId] = defaultGridState;
        },
    },
});

export const dataGridUIReducer = dataGridUISlice.reducer;
export const dataGridUIActions = dataGridUISlice.actions;
