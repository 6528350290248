import DataGrid from '@/components/DataGrid';
import { minWidthColumnProps } from '@/components/DataGridComponents';
import { commands } from '@/definitions/autogenerated/commands';
import { DtoERPbosLicenseCheck } from '@/definitions/autogenerated/types';
import { Log } from '@/helpers/debug';
import useDataGridState from '@/hooks/useDataGridState';
import { FileDownload, Send } from '@mui/icons-material';
import { Box, Button, Chip, Container, Paper, styled } from '@mui/material';
import {
    GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
    GridSlotsComponent,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    useOnMount,
} from '@mui/x-data-grid-premium';
import {
    CustomDateTimePicker,
    dataGridFormatDate,
    DataGridFormattedDate,
    TypedOmit,
    useDataGridColumns,
    useDataGridRows,
} from '@progress/base-ui';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

export type LicenseCheckRow = TypedOmit<DtoERPbosLicenseCheck, 'CreatedAt'> & {
    CreatedAt: DataGridFormattedDate;
};

const LicenseChecks = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<DtoERPbosLicenseCheck[]>([]);

    const { gridState, setGridState } = useDataGridState({
        dataGridId: 'licenseChecks',
        defaults: {
            sorting: {
                [GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD]: 'asc',
                CreatedAt: 'asc',
            },
        },
    });

    const rows = useDataGridRows<LicenseCheckRow>(() => {
        return data.map((row) => {
            const { CreatedAt, ...rest } = row;
            return {
                ...rest,
                CreatedAt: dataGridFormatDate(CreatedAt, 'D T'),
            };
        });
    }, [data]);

    const [filterStart, setFilterStart] = useState<DateTime | null>(DateTime.now().startOf('day').minus({ days: 30 }));
    const [filterEnd, setFilterEnd] = useState<DateTime | null>(DateTime.now().startOf('day').plus({ days: 1 }));

    const loadData = useCallback(() => {
        setLoading(true);
        commands
            .LoadERPbosLicenseChecks({ StartTimeStamp: filterStart!.toISO()!, EndTimeStamp: filterEnd!.toISO()! })
            .then((result) => {
                setData(result.Result);
            })
            .catchError((e) => {
                enqueueSnackbar(`Error: ${e.Description}`, { variant: 'error' });
                Log.error(e.Description);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [filterStart, filterEnd, enqueueSnackbar]);

    useOnMount(() => {
        loadData();
    });

    const columns = useDataGridColumns<LicenseCheckRow>((builder) => {
        builder
            .addColumn('Id', {
                caption: 'Id',
                minWidth: 75,
                maxWidth: 150,
                width: 100,
            })
            .addColumn('ServiceName', {
                ...minWidthColumnProps,
                caption: 'Service Name',
                width: 225,
            })
            .addColumn('MachineName', {
                ...minWidthColumnProps,
                caption: 'Machine Name',
                width: 350,
            })
            .addColumn('UserName', {
                ...minWidthColumnProps,
                width: 225,
                caption: 'User Name',
            })
            .addColumn('PublicUrl', {
                ...minWidthColumnProps,
                caption: 'Public Url',
                width: 500,
            })
            .addDateTimeColumn('CreatedAt', {
                ...minWidthColumnProps,
                flexWidth: undefined,
                width: 200,
            });
    }, []);

    return (
        <StyledContainer maxWidth="lg">
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div style={divStyleHead}>
                    <Box
                        m={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <h1 className="containerTitle">License Checks</h1>
                    </Box>
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <CustomDateTimePicker
                        sx={{ m: 0, width: '200px' }}
                        label="From"
                        value={filterStart!}
                        maxDate={DateTime.now().plus({ days: 1 })}
                        onChange={setFilterStart}
                    />
                    <CustomDateTimePicker
                        sx={{ m: 0, width: '200px' }}
                        label="To"
                        value={filterEnd!}
                        maxDate={DateTime.now().plus({ days: 1 })}
                        onChange={setFilterEnd}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ marginLeft: '10px', height: '40px' }}
                        onClick={loadData}
                    >
                        <Send />
                    </Button>
                </div>

                <Paper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        flexGrow: 1,
                        height: '1px',
                        marginTop: '8px',
                    }}
                >
                    <DataGrid
                        dataGridId="licenseChecks"
                        rows={rows}
                        columns={columns}
                        gridState={gridState}
                        getRowId={getRowId}
                        onGridStateChange={setGridState}
                        slots={slots}
                        loading={isLoading}
                        disableAggregation
                    />
                </Paper>
            </div>
        </StyledContainer>
    );
};

export default LicenseChecks;

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '2100px !important',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const divStyleHead: React.CSSProperties = {
    display: 'inline-block',
    width: '100%',
    alignItems: 'relative',
    margin: '1',
};

const getRowId = (row: LicenseCheckRow) => `${row.Id}`;

const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    const exportAsExcel = useCallback(() => {
        const allColumns = apiRef.current.getAllColumns();
        const filteredColumns = allColumns.filter((c) => !c.field.startsWith('__row_group_by')).map((c) => c.field);
        apiRef.current.exportDataAsExcel({
            fields: filteredColumns,
            fileName: 'ProgressDashboard_LicenseChecks',
        });
    }, [apiRef]);

    const groupByServiceName = useCallback(() => {
        if (!apiRef.current.state.rowGrouping.model.includes('ServiceName')) {
            apiRef.current.setRowGroupingModel(['ServiceName']);
        } else {
            apiRef.current.setRowGroupingModel([]);
        }
    }, [apiRef]);

    return (
        <GridToolbarContainer sx={{ padding: '8px' }}>
            Group by:
            <Chip
                label="ServiceName"
                onClick={groupByServiceName}
                variant="filled"
                style={{ marginLeft: 10 }}
            />
            <div style={{ flexGrow: 1 }}></div>
            <GridToolbarQuickFilter
                autoComplete="off"
                variant="outlined"
                size="small"
                sx={{ p: 0 }}
            />
            {/* <div style={{ : 1 }}></div> */}
            <Button
                onClick={exportAsExcel}
                variant="outlined"
                size="small"
                startIcon={<FileDownload />}
            >
                Excel Export
            </Button>
        </GridToolbarContainer>
    );
};

const slots: Partial<GridSlotsComponent> = {
    toolbar: CustomToolbar,
};
