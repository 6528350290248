import { Tooltip } from '@mui/material';
import { GridGroupNode } from '@mui/x-data-grid-premium';
import { DependencyList, useMemo } from 'react';
import {
    DataGridColDef,
    DataGridColumnBuilder,
    DataGridColumnProps,
    DataGridColumns,
    DataGridFormattedDate,
    DataGridRowId,
    DataGridRowKey,
    DataGridRowType,
} from './baseDataGridTypes';

/** helper function to map DataGridColumnProps to DataGridColDef */
const mapOptions = <T extends DataGridRowType>(options: DataGridColumnProps): Partial<DataGridColDef<T>> => {
    const colDef: Partial<DataGridColDef<T>> = {};
    colDef.headerName = options.caption ?? '';
    colDef.description = options.toolTip ?? colDef.headerName;
    options.align && (colDef.align = options.align);
    options.alignCaption && (colDef.headerAlign = options.alignCaption);
    options.showMenu === false && (colDef.disableColumnMenu = true); //defaults to true (disableColumnMenu defaults to false)
    options.exportable === false && (colDef.disableExport = true); //defaults to true (disableExport defaults to false)
    options.resizable === false && (colDef.resizable = false); //defaults to true
    options.reorderable === false && (colDef.disableReorder = true); //defaults to true (disableReorder defaults to false)
    options.sortable === false && (colDef.sortable = false); //defaults to true
    options.reorderable === false && (colDef.disableReorder = true); //defaults to true (disableReorder defaults to false)
    options.groupable === false && (colDef.groupable = false); //defaults to true
    options.aggregable === false && (colDef.aggregable = false);
    options.filterable === false && (colDef.filterable = false); //defaults to true
    options.pinnable === false && (colDef.pinnable = false); //defaults to true
    options.editable && (colDef.editable = true);
    options.sortingOrder && (colDef.sortingOrder = options.sortingOrder);
    options.className && (colDef.cellClassName = options.className); //defaults to undefined
    options.span && (colDef.colSpan = options.span); //defaults to 1
    options.flexWidth && (colDef.flex = options.flexWidth); //defaults to undefined
    options.minWidth && (colDef.minWidth = options.minWidth); //defaults to 50
    options.maxWidth && (colDef.maxWidth = options.maxWidth); //defaults to Infinity
    options.width && (colDef.width = options.width); //defaults to 100
    colDef.display = 'flex'; // to make dom elements centered by default
    return colDef;
};

/**
 * Hook to create a DataGrid column configuration. The use of this hook is mandatory for BaseDataGrid.
 *
 * @param {Function} build a function that receives a DataGridColumnBuilder and is expected to add columns with the builder methods.
 * @param {DependencyList} deps a list of dependencies that will trigger a rebuild of the column configuration.
 * @returns {DataGridColDef<T>[]} the DataGrid column configuration.
 */
const useDataGridColumns = <T extends DataGridRowType>(
    build: (builder: DataGridColumnBuilder<T>) => void,
    deps: DependencyList
): DataGridColumns<T> => {
    const columns = useMemo(() => {
        const dataGridColumns: DataGridColDef<T>[] = [];

        const builder: DataGridColumnBuilder<T> = {
            addColumn: (field, options) => {
                const column: DataGridColDef<T> = {
                    _columnType: 'primitive',
                    field: field,
                    ...mapOptions(options ?? {}),
                    valueGetter: (value, row) => {
                        if (options?.getValue) return options.getValue({ value: row[field], row, field });
                        return value;
                    },
                    groupingValueGetter: (value, row) => {
                        if (options?.getGroupValue) return options.getGroupValue({ value: row[field], row, field });
                        if (options?.getValue) return options.getValue({ value: row[field], row, field });
                        return value;
                    },
                    renderCell: ({ value, row, rowNode }) => {
                        if (rowNode.type === 'group') {
                            const groupNode = rowNode as GridGroupNode;
                            if (groupNode.groupingField !== field) return null;
                            if (options?.renderGroup) return options.renderGroup({ field, row, value });
                            return value; //<< do not trigger "render" when rendering a group.
                        }
                        if (options?.render) return options.render({ field, row, value: row[field] }); //<< do not stack "getValue" and "render"
                        return value;
                    },
                    renderHeader: ({ colDef: { headerName: caption, description } }) => {
                        if (options?.renderCaption) return options.renderCaption();
                        return !description ? (
                            caption
                        ) : (
                            <Tooltip title={description}>
                                <div>{caption}</div>
                            </Tooltip>
                        );
                    },
                };
                if (options?.sortCompare)
                    column.sortComparator = ({ cellParams1, cellParams2 }) => {
                        if (options?.sortCompare) return options.sortCompare(cellParams1.value, cellParams2.value);
                        return 0; // sortCompare must de facto be set here
                    };
                /** disable the quick filter on this column */
                if (options?.searchable === false) column.getApplyQuickFilterFn = () => () => false;
                dataGridColumns.push(column);
                return builder;
            },
            addDateTimeColumn: (field, options) => {
                const column: DataGridColDef<T> = {
                    _columnType: 'dateTime',
                    field,
                    ...mapOptions(options ?? {}),
                    valueGetter: (value: DataGridFormattedDate | null) => value?.value,
                    valueFormatter: (value, row) => (row[field] as DataGridFormattedDate | null)?.formatted,
                    groupingValueGetter: (value, row) => (row[field] as DataGridFormattedDate | null)?.formatted,
                    renderCell: ({ row, value, rowNode }) => {
                        const dateValue = row[field] as DataGridFormattedDate;
                        if (rowNode.type === 'group') {
                            const groupNode = rowNode as GridGroupNode;
                            if (groupNode.groupingField !== field) return null;
                            if (options?.renderGroup) return options.renderGroup({ field, row, value });
                            return value; //<< do not trigger "render" when rendering a group.
                        }
                        if (options?.render) return options.render({ field, row, value: row[field] }); //<< do not stack "getValue" and "render"
                        return dateValue?.formatted ?? '';
                    },
                    renderHeader: ({ colDef: { headerName: caption, description } }) => {
                        if (options?.renderCaption) return options.renderCaption();
                        return !description ? (
                            caption
                        ) : (
                            <Tooltip title={description}>
                                <div>{caption}</div>
                            </Tooltip>
                        );
                    },
                };
                if (options?.sortCompare)
                    column.sortComparator = ({ cellParams1, cellParams2 }) => {
                        if (options?.sortCompare) return options.sortCompare(cellParams1.value, cellParams2.value);
                        return 0; // sortCompare must de facto be set here
                    };
                /** disable the quick filter on this column */
                if (options?.searchable === false) column.getApplyQuickFilterFn = () => () => false;
                dataGridColumns.push(column);
                return builder;
            },
            addObjectColumn: (field, options) => {
                const column: DataGridColDef<T> = {
                    _columnType: 'object',
                    field: field,
                    ...mapOptions(options ?? {}),
                    valueGetter: (value, row) => options.getValue({ value: row[field], row, field }),
                    groupingValueGetter: (value, row) => {
                        if (options.getGroupValue) return options.getGroupValue({ value: row[field], row, field });
                        return options.getValue({ value: row[field], row, field });
                    },
                    renderCell: ({ row, value, rowNode }) => {
                        if (rowNode.type === 'group') {
                            const groupNode = rowNode as GridGroupNode;
                            if (groupNode.groupingField !== field) return null;
                            if (options?.renderGroup) return options.renderGroup({ field, row, value });
                            return value; //<< do not trigger "render" when rendering a group.
                        }
                        if (options?.render) return options.render({ field, row, value: row[field] }); //<< do not stack "getValue" and "render"
                        return value;
                    },
                    renderHeader: ({ colDef: { headerName: caption, description } }) => {
                        if (options?.renderCaption) return options.renderCaption();
                        return !description ? (
                            caption
                        ) : (
                            <Tooltip title={description}>
                                <div>{caption}</div>
                            </Tooltip>
                        );
                    },
                };
                if (options?.sortCompare)
                    column.sortComparator = ({ cellParams1, cellParams2 }) => {
                        if (options?.sortCompare) return options.sortCompare(cellParams1.value, cellParams2.value);
                        return 0; // sortCompare must de facto be set here
                    };
                /** disable the quick filter on this column */
                if (options?.searchable === false) column.getApplyQuickFilterFn = () => () => false;
                dataGridColumns.push(column);
                return builder;
            },
            addActionColumn: (field, options) => {
                dataGridColumns.push({
                    _columnType: 'action',
                    field: field,
                    ...mapOptions({ ...(options ?? {}), searchable: false }),
                    editable: false,
                    groupable: false,
                    sortable: false,
                    aggregable: false,
                    filterable: false,
                    disableExport: true,
                    renderCell: ({ row, id, rowNode }) => {
                        if (rowNode.type !== 'leaf') return null;
                        return options.render({ field: field as DataGridRowKey<T>, row, rowId: id as DataGridRowId });
                    },
                    renderHeader: ({ colDef: { headerName: caption, description } }) => {
                        if (options?.renderCaption) return options.renderCaption();
                        return !description ? (
                            caption
                        ) : (
                            <Tooltip title={description}>
                                <div>{caption}</div>
                            </Tooltip>
                        );
                    },
                });
                return builder;
            },
        };
        (build as (builder: DataGridColumnBuilder<T>) => void)(builder);
        return dataGridColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps -- the dependency list is not exhaustive
    }, [...deps]);

    return useMemo<DataGridColumns<T>>(() => ({ columns, _origin_: 'useDataGridColumns' }), [columns]);
};

export default useDataGridColumns;
