import 'ace-builds/src-noconflict/ace'; // this must be the first ace import!!!
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import './custom.scss';
import { routes } from './definitions';
import { AuthenticationUtils } from './helpers/authenticationUtils';
import About from './pages/AboutPage';
import ConfigSheet from './pages/configSheetEditor/ConfigSheet';
import CustomerList from './pages/CustomerList/CustomerList';
import GitHubList from './pages/GithubList';
import LicenseChecks from './pages/LicenseChecks/LicenseChecks';
import PipelineOverview from './pages/PipelineOverview';
import ProgressAppsList from './pages/ProgressAppsList';
import ProgressBinUpdater from './pages/ProgressBinUpdater';
import Tools from './pages/Tools';

const hasLicenseCheckPermissions = AuthenticationUtils.hasLicenseCheckPermissions();

const App = () => {
    return (
        <Layout>
            <Switch>
                <Route
                    exact
                    path={routes.customerList}
                    component={CustomerList}
                />
                <Route
                    exact
                    path={routes.progressApps}
                    component={ProgressAppsList}
                />
                <Route
                    exact
                    path={routes.progressBinUpdater}
                    component={ProgressBinUpdater}
                />
                <Route
                    exact
                    path={routes.configSheet}
                    component={ConfigSheet}
                />
                <Route
                    exact
                    path={routes.about}
                    component={About}
                />
                <Route
                    exact
                    path={routes.githubList}
                    component={GitHubList}
                />
                <Route
                    exact
                    path={routes.pipelines}
                    component={PipelineOverview}
                />
                {hasLicenseCheckPermissions && (
                    <Route
                        exact
                        path={routes.licenseChecks}
                        component={LicenseChecks}
                    />
                )}
                <Route
                    exact
                    path={routes.tools}
                    component={Tools}
                />

                <Redirect
                    exact
                    from="/"
                    to={routes.customerList}
                />

                <Route render={() => <Redirect to={routes.customerList} />} />
            </Switch>
        </Layout>
    );
};

export default App;
